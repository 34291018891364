export const mediaQueriesDesktopFirst = {
    lgDesktop: 1599,
    mdDesktop: 1279,
    smDesktop: 1099,
    lsTablet: 1023,
    ptTablet: 800,
    lgMobile: 767,
    mdMobile: 479,
    smMobile: 413,
};

export const mediaQueriesMobileFirst = {
    lgDesktop: 1280,
    mdDesktop: 1100,
    smDesktop: 1024,
    lsTablet: 801,
    ptTablet: 768,
    lgMobile: 480,
    mdMobile: 414,
    smMobile: 360,
};

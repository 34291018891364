import { Children, PropsWithChildren, ReactNode, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const Carousel: React.FC<CarouselProps> = ({
    children,
    slidesPerView = 1,
    loop = false,
    onChange,
}) => {
    const childArray = Children.toArray(children);
    const [_, setControlledSwiper] = useState<unknown | null>(null); // eslint-disable-line

    return (
        <div className="carousel-container">
            <div className="carousel-wrapper">
                <Swiper
                    slidesPerView={slidesPerView}
                    onSlideChange={swiper => onChange && onChange(swiper.activeIndex)}
                    onSwiper={swiper => setControlledSwiper(swiper)}
                    loop={loop}
                    role="swiper"
                >
                    {childArray.map((child: ReactNode, i: number) => (
                        <SwiperSlide key={`slide-${i}`} role="swiper-slide">
                            {child}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

interface CarouselProps extends PropsWithChildren {
    slidesPerView?: number;
    loop?: boolean;
    onChange?: (index: number) => void;
}

export default Carousel;

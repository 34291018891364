import useFetchTopicsBehaviors from '@pages/assessment/hooks/useFetchTopicsBehaviors';
import useFetchLatestAssessmentResults from '@pages/latestAssessment/hooks/useFetchLatestAssessmentResults';

const useAssessmentDetails = () => {
    const {
        latestAssessmentResults,
        isFetching: isFetchingResults,
        fetchError: resultsFetchError,
    } = useFetchLatestAssessmentResults();
    const { isFetching: isFetchingTopics, fetchError: topicsFetchError } =
        useFetchTopicsBehaviors();

    const isFetching = isFetchingResults || isFetchingTopics;
    const fetchError = resultsFetchError || topicsFetchError;

    return {
        latestAssessmentResults,
        isFetching,
        fetchError,
    };
};

export default useAssessmentDetails;

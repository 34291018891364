import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    validatePeerAssessmentRequest,
    validatePeerAssessmentSuccess,
    validatePeerAssessmentFailure,
} from '@actions/peerAssessment/validatePeerAsssessment';

import {
    AssessmentAnswerResponse,
    AssessmentValidationResponse,
    LatestAssessment,
    LatestAssessmentResultsResponse,
} from '../../types/shared/Assessment';
import {
    fetchPeerAssessmentFailure,
    fetchPeerAssessmentRequest,
    fetchPeerAssessmentSuccess,
} from '@actions/peerAssessment/fetchPeerAssessment';

import {
    postPeerAssessmentAnswerRequest,
    postPeerAssessmentAnswerSuccess,
    postPeerAssessmentAnswerFailure,
} from '@actions/peerAssessment/postPeerAssessmentAnswer';
import {
    fetchPeerAssessmentResultsRequest,
    fetchPeerAssessmentResultsSuccess,
    fetchPeerAssessmentResultsFailure,
} from '@actions/peerAssessment/fetchPeerAssessmentResults';
import {
    fetchPeerAssessmentInfoRequest,
    fetchPeerAssessmentInfoSuccess,
} from '@actions/peerAssessment/fetchPeerAssessmentInfo';

export interface PeerAssessmentInfo {
    firstName: string;
    lastName: string;
    assessmentID: number;
}

interface PeerAssessmentState {
    isValidating: boolean;
    validationError: string | null;
    validationSuccess: boolean;
    validationResponse: AssessmentValidationResponse | null;
    assessment: LatestAssessment;
    isFetching: boolean;
    error: string | null;
    isPosting: boolean;
    postError: string | null;
    postAnswerSuccess: boolean;
    peerAssessmentAnswer: AssessmentAnswerResponse | null;
    peerAssessmentResults: LatestAssessmentResultsResponse | null;
    peerAssessmentInfo: PeerAssessmentInfo;
}

const initialState: PeerAssessmentState = {
    isValidating: false,
    validationError: null,
    validationSuccess: false,
    validationResponse: null,
    assessment: {} as LatestAssessment,
    isFetching: false,
    error: null,
    isPosting: false,
    postError: null,
    postAnswerSuccess: false,
    peerAssessmentAnswer: null,
    peerAssessmentResults: null,
    peerAssessmentInfo: {} as PeerAssessmentInfo,
};

export default createReducer(initialState, {
    [validatePeerAssessmentRequest.type]: handleValidateAssessmentRequest,
    [validatePeerAssessmentSuccess.type]: handleValidateAssessmentSuccess,
    [validatePeerAssessmentFailure.type]: handleValidateAssessmentFailure,
    [fetchPeerAssessmentRequest.type]: handlFetchRequest,
    [fetchPeerAssessmentSuccess.type]: handleFetchQuestionsSuccess,
    [fetchPeerAssessmentFailure.type]: handleFetchFailure,
    [postPeerAssessmentAnswerRequest.type]: handlePostAnswerRequest,
    [postPeerAssessmentAnswerSuccess.type]: handlePostAnswerSuccess,
    [postPeerAssessmentAnswerFailure.type]: handlePostFailure,
    [fetchPeerAssessmentResultsRequest.type]: handlFetchRequest,
    [fetchPeerAssessmentResultsSuccess.type]: handleFetchResultsSuccess,
    [fetchPeerAssessmentResultsFailure.type]: handleFetchFailure,
    [fetchPeerAssessmentInfoRequest.type]: handlFetchRequest,
    [fetchPeerAssessmentInfoSuccess.type]: handleFetchInfoSuccess,
    [fetchPeerAssessmentFailure.type]: handleFetchFailure,
});

function handleValidateAssessmentRequest(state: PeerAssessmentState) {
    state.isValidating = true;
    state.validationError = null;
    state.validationSuccess = false;
}

function handleValidateAssessmentSuccess(
    state: PeerAssessmentState,
    action: PayloadAction<AssessmentValidationResponse>,
) {
    state.isValidating = false;
    state.validationSuccess = true;
    state.validationResponse = action.payload;
}

function handleValidateAssessmentFailure(
    state: PeerAssessmentState,
    action: PayloadAction<string>,
) {
    state.isValidating = false;
    state.validationError = action.payload;
}

function handlFetchRequest(state: PeerAssessmentState) {
    state.isFetching = true;
    state.error = null;
    state.peerAssessmentInfo = {} as PeerAssessmentInfo;
}

function handlePostAnswerRequest(state: PeerAssessmentState) {
    state.postAnswerSuccess = false;
    state.isPosting = true;
    state.postError = null;
}

function handleFetchQuestionsSuccess(
    state: PeerAssessmentState,
    action: PayloadAction<LatestAssessment>,
) {
    state.isFetching = false;
    state.assessment = action.payload;
}

function handleFetchFailure(state: PeerAssessmentState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}

function handlePostFailure(state: PeerAssessmentState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handlePostAnswerSuccess(
    state: PeerAssessmentState,
    action: PayloadAction<AssessmentAnswerResponse>,
) {
    state.isPosting = false;
    state.postAnswerSuccess = true;
    state.peerAssessmentAnswer = action.payload;
    if (state.assessment) {
        const questionToUpdate = state.assessment.questions.find(
            question => question.id === action.payload.questionID,
        );
        if (questionToUpdate) {
            questionToUpdate.answer = action.payload.answer
                ? +action.payload.answer
                : action.payload.answer;
        }
    }
}

function handleFetchResultsSuccess(
    state: PeerAssessmentState,
    action: PayloadAction<LatestAssessmentResultsResponse>,
) {
    state.isFetching = false;
    state.peerAssessmentResults = action.payload;
}

function handleFetchInfoSuccess(
    state: PeerAssessmentState,
    action: PayloadAction<PeerAssessmentInfo>,
) {
    state.isFetching = false;
    state.peerAssessmentInfo = action.payload;
}

import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    postPeerInvitationFailure,
    postPeerInvitationRequest,
    postPeerInvitationSuccess,
} from '@actions/peerInvitation/postPeerInvitation';
import { InvitedPeers } from 'src/types/shared/PeerInvitation';
import {
    fetchPeerInvitationFailure,
    fetchPeerInvitationRequest,
    fetchPeerInvitationSuccess,
} from '@actions/peerInvitation';

interface InvitePeersState {
    isFetching: boolean;
    isPosting: boolean;
    postError: string | null;
    fetchError: string | null;
    postInvitePeerSuccess: boolean;
    invitedPeersList: InvitedPeers[];
}

const initialState: InvitePeersState = {
    isPosting: false,
    isFetching: false,
    postError: null,
    fetchError: null,
    postInvitePeerSuccess: false,
    invitedPeersList: [],
};

export default createReducer(initialState, {
    [fetchPeerInvitationRequest.type]: handleFetchRequest,
    [fetchPeerInvitationSuccess.type]: handleFetchPeerInvitationSuccess,
    [fetchPeerInvitationFailure.type]: handleFetchError,
    [postPeerInvitationRequest.type]: handlePostRequest,
    [postPeerInvitationSuccess.type]: handlePostInvitePeerSuccess,
    [postPeerInvitationFailure.type]: handlePostError,
});

function handleFetchRequest(state: InvitePeersState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchPeerInvitationSuccess(
    state: InvitePeersState,
    action: PayloadAction<InvitedPeers[]>,
) {
    state.isFetching = false;
    state.invitedPeersList = action.payload;
}

function handleFetchError(state: InvitePeersState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handlePostRequest(state: InvitePeersState) {
    state.isPosting = true;
    state.postError = null;
    state.postInvitePeerSuccess = false;
}

function handlePostError(state: InvitePeersState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handlePostInvitePeerSuccess(
    state: InvitePeersState,
    action: PayloadAction<InvitedPeers[]>,
) {
    state.isPosting = false;
    state.postError = null;
    state.postInvitePeerSuccess = true;
    state.invitedPeersList = [...state.invitedPeersList, ...action.payload];
}

import { TAuthTokenPayload, useOAuth2 } from '@tasoskakour/react-use-oauth2';

import Meta from 'lib/src/components/meta/Meta';
import { OAUTH_CLIENT_ID, OAUTH_REDIRECT_URL, OAUTH_URL, API_URL } from '@config/index';
import { useHistory } from 'react-router-dom';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Pod from '@components/pods/Pod';
import PageHeader from '@components/typography/PageHeader';
import PageDescription from '@components/typography/PageDescription';
import Form from 'lib/src/components/form/Form';
import { clearJwtAndRefreshToken } from 'lib/src/utils/jwt';

const Login: React.FC = () => {
    const history = useHistory();
    const handleSuccess = (payload: DecathlonAuthTokenPayload) => {
        const { access_token, id_token, refresh_token } = payload;
        localStorage.setItem('jwt', access_token);
        localStorage.setItem('idToken', id_token);
        localStorage.setItem('refreshToken', refresh_token);
        setTimeout(() => history.push('/'), 500);
    };
    const { loading, getAuth } = useOAuth2({
        authorizeUrl: OAUTH_URL,
        clientId: OAUTH_CLIENT_ID,
        redirectUri: OAUTH_REDIRECT_URL,
        scope: 'openid profile email',
        responseType: 'code',
        exchangeCodeForTokenQuery: {
            url: `${API_URL}/oauth/token`,
            method: 'POST',
        },
        onSuccess: (payload: DecathlonAuthTokenPayload) => handleSuccess(payload),
        onError: error_ => console.log('Error', error_),
    });

    const handleLogin = () => {
        clearJwtAndRefreshToken();
        setTimeout(getAuth, 500);
    };

    return (
        <FlexWrapper id="login-page" direction="column" extraClasses="full-page area-padding">
            <Meta title="Login" />

            <Pod>
                <PageHeader>Login</PageHeader>

                <PageDescription>To access your dashboard, please login below.</PageDescription>
                <Form
                    onSubmit={handleLogin}
                    isPosting={loading}
                    omitCancelButton
                    buttonAlignment="center"
                    submitText="Login"
                />
            </Pod>
        </FlexWrapper>
    );
};

interface DecathlonAuthTokenPayload extends TAuthTokenPayload {
    id_token: string;
}

export default Login;

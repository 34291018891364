import { Link, useParams } from 'react-router-dom';

import Pod from '@components/pods/Pod';
import PageDescription from '@components/typography/PageDescription';
import ActionButton from 'lib/src/components/button/ActionButton';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Meta from 'lib/src/components/meta/Meta';
import Title from 'lib/src/components/typography/Title';
import CompletionBackgroundImage from '../../_content/completion-background.jpeg';
import SecondaryBackground from '@components/secondaryBackground/SecondaryBackground';

const CheckResults = () => {
    const { assessmentCode } = useParams<{ assessmentCode: string }>();

    return (
        <FlexWrapper
            id="check-results"
            direction="column"
            align="center"
            extraClasses="full-page area-padding"
        >
            <Meta title="Assessment Complete" />
            <SecondaryBackground
                src={CompletionBackgroundImage}
                alt="People raising hands cheerfully on a canoe"
            />

            <FlexWrapper direction="column" align="center" extraClasses="greeting-pod">
                <Pod
                    extraStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginBottom: '30px',
                    }}
                >
                    <Title>Thank you for completing the survey.</Title>

                    <PageDescription>
                        We recommend that you now ask your leader, all of your team (if applicable),
                        and some peers to receive 360 feedback on your leadership. Once done you
                        will be able to review your results.
                        <br />
                        <br />
                        For a comprehensive overview and graph of your results, head to the desktop
                        version of this survey.
                    </PageDescription>

                    <FlexWrapper direction="column" align="center">
                        <Link
                            to={`/peer-invitation/${assessmentCode}`}
                            style={{ textDecoration: 'none', width: '90%' }}
                        >
                            <ActionButton source="positive" autoWidth={false} width={12}>
                                Request Feedback
                            </ActionButton>
                        </Link>
                        <Link
                            to={`/latest-assessment`}
                            style={{ textDecoration: 'none', marginTop: '10px', width: '90%' }}
                        >
                            <ActionButton source="secondary" autoWidth={false} width={12}>
                                View Results
                            </ActionButton>
                        </Link>
                    </FlexWrapper>
                </Pod>
            </FlexWrapper>
        </FlexWrapper>
    );
};

export default CheckResults;

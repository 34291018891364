import { useMemo } from 'react';
import { AssessmentResult } from 'src/types/shared/Assessment';
import { BehaviourResult, TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import { BehaviourDataPoints, ChartTopic } from 'lib/src/components/charts/AssessmentChart';
import { DATA_POINT_COLOURS } from 'lib/src/constants/chart';

const useBehaviourDataPointsForAssessmentChart = (
    topicsBehaviors: TopicsBehaviour,
    peerAverageResults: AssessmentResult,
    leaderAverageResults: AssessmentResult,
    teamAverageResults: AssessmentResult,
    topicsForAssessmentChart: ChartTopic[],
) => {
    const behaviourDataPointsForAssessmentChart: BehaviourDataPoints[] = useMemo(() => {
        const behaviours = [...topicsBehaviors.behaviours]
            .map(behaviour => ({
                ...behaviour,
                sort:
                    topicsForAssessmentChart.find(topic => topic.id === behaviour.topicID)?.sort ||
                    0,
            }))
            .sort((a, b) => a.sort - b.sort || a.topicID - b.topicID || a.id - b.id)
            .map(behaviour => {
                let relatedPeerAssessmentBehaviour: BehaviourResult | undefined = undefined;
                let relatedLeaderAssessmentBehaviour: BehaviourResult | undefined = undefined;
                let relatedTeamAssessmentBehaviour: BehaviourResult | undefined = undefined;

                if (peerAverageResults) {
                    const relatedPeerAssessmentTopic = peerAverageResults.topics.find(
                        t => t.id === behaviour.topicID,
                    );
                    relatedPeerAssessmentBehaviour = relatedPeerAssessmentTopic?.behaviours.find(
                        b => b.id === behaviour.id,
                    );
                }

                if (leaderAverageResults) {
                    const relatedLeaderAssessmentTopic = leaderAverageResults.topics.find(
                        t => t.id === behaviour.topicID,
                    );
                    relatedLeaderAssessmentBehaviour =
                        relatedLeaderAssessmentTopic?.behaviours.find(b => b.id === behaviour.id);
                }

                if (teamAverageResults) {
                    const relatedTeamAssessmentTopic = teamAverageResults.topics.find(
                        t => t.id === behaviour.topicID,
                    );
                    relatedTeamAssessmentBehaviour = relatedTeamAssessmentTopic?.behaviours.find(
                        b => b.id === behaviour.id,
                    );
                }

                return {
                    id: behaviour.id,
                    points: [
                        {
                            name: 'Zone of excellence',
                            colour: DATA_POINT_COLOURS.ZONE_OF_EXCELLENCE,
                            percentage: 95,
                        },
                        {
                            name: 'Leader feedback',
                            colour: DATA_POINT_COLOURS.SECOND,
                            percentage: relatedLeaderAssessmentBehaviour?.percentage || 0,
                        },
                        {
                            name: 'Team feedback',
                            colour: DATA_POINT_COLOURS.THIRD,
                            percentage: relatedTeamAssessmentBehaviour?.percentage || 0,
                        },
                        {
                            name: 'All feedback',
                            colour: DATA_POINT_COLOURS.FOURTH,
                            percentage: relatedPeerAssessmentBehaviour?.percentage || 0,
                        },
                    ],
                };
            });
        return behaviours;
    }, [
        topicsBehaviors.behaviours,
        peerAverageResults,
        topicsForAssessmentChart,
        leaderAverageResults,
        teamAverageResults,
    ]);

    return behaviourDataPointsForAssessmentChart;
};

export default useBehaviourDataPointsForAssessmentChart;

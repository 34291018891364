import { combineReducers } from 'redux';
import darkModeReducer from 'lib/src/redux/reducers/darkMode';
import fieldErrorsReducer from 'lib/src/redux/reducers/fieldErrors';
import redirectsReducer from 'lib/src/redux/reducers/redirects';
import questionsReducer from './assessmentQuestions';
import topicsBehaviorsReducer from './topicsBehaviors';
import assessmentReducer from './assessment';
import invitePeersReducer from './invitePeers';
import peerAssessmentsReducer from './peerAssessments';
import peerAssessmentQuestionsReducer from './peerAssessmentQuestions';

const rootReducer = combineReducers({
    darkModeReducer,
    fieldErrorsReducer,
    redirectsReducer,
    questionsReducer,
    topicsBehaviorsReducer,
    assessmentReducer,
    invitePeersReducer,
    peerAssessmentsReducer,
    peerAssessmentQuestionsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { LatestAssessment } from '../../../types/shared/Assessment';

export const fetchPeerAssessmentQuestionsRequest = createAction(
    'fetchPeerAssessmentQuestionsRequest',
);
export const fetchPeerAssessmentQuestionsSuccess = createAction<LatestAssessment>(
    'fetchPeerAssessmentQuestionsSuccess',
);
export const fetchPeerAssessmentQuestionsFailure = createAction(
    'fetchPeerAssessmentQuestionsFailure',
);

export const fetchPeerAssessmentQuestions =
    (code: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchPeerAssessmentQuestionsRequest());
        try {
            const { data } = await api.get<LatestAssessment>(`peer/questions/${code}`);
            dispatch(fetchPeerAssessmentQuestionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchPeerAssessmentQuestionsFailure, e as APIError);
        }
    };

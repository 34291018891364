import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { LatestAssessmentResultsResponse } from 'src/types/shared/Assessment';

export const fetchLatestAssessmentResultsRequest = createAction(
    'fetchLatestAssessmentResultsRequest',
);
export const fetchLatestAssessmentResultsSuccess = createAction<LatestAssessmentResultsResponse>(
    'fetchLatestAssessmentResultsSuccess',
);
export const fetchLatestAssessmentResultsFailure = createAction(
    'fetchLatestAssessmentResultsFailure',
);

export const fetchLatestAssessmentResults =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchLatestAssessmentResultsRequest());
        try {
            const { data } = await api.get<LatestAssessmentResultsResponse>('assessments/latest');
            dispatch(fetchLatestAssessmentResultsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchLatestAssessmentResultsFailure, e as APIError);
        }
    };

import React from 'react';

const SecondaryBackground: React.FC<SecondaryBackgroundProps> = ({ src, alt = 'Background' }) => {
    return <img className="secondary-background" alt={alt} src={src} />;
};

interface SecondaryBackgroundProps {
    src: string;
    alt?: string;
}

export default SecondaryBackground;

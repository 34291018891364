export enum InviteeTypes {
    Leader = 1,
    Team = 2,
    Other = 3,
}

export const InviteeTypesNames = {
    1: 'My Leader',
    2: 'My Team',
    3: 'Other',
};

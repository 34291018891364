import { RootState } from '@reducers/index';
import { AssessmentQuestion } from 'src/types/shared/Assessment';

export const getAssessmentQuestions = (state: RootState): Record<number, AssessmentQuestion> =>
    state.questionsReducer.assessmentQuestions;
export const getAssessmentQuestionsArr = (state: RootState): AssessmentQuestion[] =>
    Object.values(state.questionsReducer.assessmentQuestions);

export const getAssessmentQuestionsShuffledArr = (state: RootState): AssessmentQuestion[] =>
    Object.values(state.questionsReducer.assessmentQuestionsShuffled);

export const getAssessmentQuestionsIsFetching = (state: RootState): boolean =>
    state.questionsReducer.isFetching;
export const getAssessmentQuestionsFetchError = (state: RootState): string | null =>
    state.questionsReducer.fetchError;
export const getAssessmentQuestionFetchSuccess = (state: RootState): boolean =>
    state.questionsReducer.fetchSuccess;

import Pod from '@components/pods/Pod';
import Title from 'lib/src/components/typography/Title';
import LinkButton from 'lib/src/components/button/LinkButton';
import { CompareTopicResultWithInfo } from './hooks/useCompareResults';
import AssessmentDetailsChart, {
    BarProps,
    ReferenceLineProps,
} from 'lib/src/components/charts/AssessmentDetailsChart';
import useWindowDimensions from 'lib/src/hooks/useWindowDimensions';
import { DATA_POINT_COLOURS } from 'lib/src/constants/chart';

const CompareResultsPod: React.FC<CompareResultsPodProps> = ({ comparisonTopicResult }) => {
    const { width } = useWindowDimensions();
    const isMobile = width < 768;
    const data = [
        {
            name: 'My Score',
            score: comparisonTopicResult.myPercentage,
        },
        {
            name: 'Average Score',
            score: comparisonTopicResult.percentage,
        },
    ];

    const bars: BarProps[] = [
        {
            dataKey: 'score',
            fill: comparisonTopicResult.colourDark,
        },
    ];

    const referenceLines: ReferenceLineProps[] = [
        {
            value: 95,
            stroke: DATA_POINT_COLOURS.ZONE_OF_EXCELLENCE,
            label: 'Zone of excellence',
        },
    ];

    const dataHeight = data.length * 30;

    return (
        <Pod extraClasses="assessment-pod">
            <div style={{ height: '50%' }}>
                <div className="title-wrapper">
                    <Title>{comparisonTopicResult.name}</Title>
                </div>
            </div>

            <div style={{ height: '50%' }} className="flex-column justify-between">
                {data?.length ? (
                    <AssessmentDetailsChart
                        data={data}
                        bars={bars}
                        referenceLines={referenceLines}
                        showTooltip
                        layout="vertical"
                        radius={[0, 50, 50, 0]}
                        containerClasses="behaviour-result-chart-container"
                        minHeight={`${dataHeight + 50}px`}
                        colour={comparisonTopicResult.colour}
                        colourDark={comparisonTopicResult.colourDark}
                        hideYAxis={isMobile}
                    />
                ) : (
                    <div>
                        <LinkButton href="">Add self assessment</LinkButton>
                    </div>
                )}
            </div>
        </Pod>
    );
};

interface CompareResultsPodProps {
    comparisonTopicResult: CompareTopicResultWithInfo;
}

export default CompareResultsPod;

import React, { ReactNode } from 'react';

const Pod: React.FC<PodProps> = ({ children, extraClasses = '', extraStyle }) => (
    <div className={`pod ${extraClasses}`} style={extraStyle}>
        {children}
    </div>
);

interface PodProps {
    children: ReactNode;
    extraClasses?: string;
    extraStyle?: React.CSSProperties;
}

export default Pod;

import {
    fetchPeerAssessmentQuestionsFailure,
    fetchPeerAssessmentQuestionsRequest,
    fetchPeerAssessmentQuestionsSuccess,
} from '@actions/peerAssessment/fetchPeerAssessmentQuestions';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import { AssessmentQuestion } from 'src/types/shared/Assessment';

interface PeerAssessmentQuestionsState {
    isFetching: boolean;
    fetchError: string | null;
    fetchSuccess: boolean;
    peerAssessmentQuestions: Record<number, AssessmentQuestion>;
    peerAssessmentQuestionsShuffled: Record<number, AssessmentQuestion>;
}

const initialState: PeerAssessmentQuestionsState = {
    isFetching: false,
    fetchError: null,
    fetchSuccess: false,
    peerAssessmentQuestions: {},
    peerAssessmentQuestionsShuffled: {},
};

export default createReducer(initialState, {
    [fetchPeerAssessmentQuestionsRequest.type]: handleFetchRequest,
    [fetchPeerAssessmentQuestionsSuccess.type]: handleFetchpeerAssessmentQuestionsSuccess,
    [fetchPeerAssessmentQuestionsFailure.type]: handleFetchError,
});

function handleFetchRequest(state: PeerAssessmentQuestionsState) {
    state.isFetching = true;
    state.fetchSuccess = false;
    state.fetchError = null;
}

function handleFetchError(state: PeerAssessmentQuestionsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchpeerAssessmentQuestionsSuccess(
    state: PeerAssessmentQuestionsState,
    action: PayloadAction<AssessmentQuestion[]>,
) {
    state.isFetching = false;
    state.fetchSuccess = true;
    state.peerAssessmentQuestions = convertArrToObj(action.payload);
}

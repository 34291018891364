import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Assessment } from 'src/types/shared/Assessment';

export const fetchMyAssessmentsRequest = createAction('fetchMyAssessmentsRequest');
export const fetchMyAssessmentsSuccess = createAction<Assessment[]>('fetchMyAssessmentsSuccess');
export const fetchMyAssessmentsFailure = createAction('fetchMyAssessmentsFailure');

export const fetchMyAssessments =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchMyAssessmentsRequest());
        try {
            const { data } = await api.get<Assessment[]>('assessments');
            dispatch(fetchMyAssessmentsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchMyAssessmentsFailure, e as APIError);
        }
    };

import { useMemo } from 'react';
import { AssessmentResult } from 'src/types/shared/Assessment';
import { ChartStats, ChartTopic } from 'lib/src/components/charts/AssessmentChart';

const useTopicStatsForAssessmentChart = (
    topicsForAssessmentChart: ChartTopic[],
    peerAverageResults: AssessmentResult,
    leaderAverageResults: AssessmentResult,
) => {
    const topicStatsForAssessmentChart: ChartStats[] = useMemo(
        () =>
            [...topicsForAssessmentChart]
                .sort((a, b) => a.sort - b.sort)
                .map(topic => {
                    const peerPercentage =
                        peerAverageResults?.topics.find(result => result.id === topic.id)
                            ?.percentage || 0;

                    const leaderPercentage =
                        leaderAverageResults?.topics.find(result => result.id === topic.id)
                            ?.percentage || 0;

                    return {
                        stats: [
                            { name: 'My leader', percentage: +leaderPercentage.toFixed(0) },
                            {
                                name: 'All feedback',
                                percentage: +peerPercentage.toFixed(0),
                            },
                        ],
                    };
                }),
        [topicsForAssessmentChart, leaderAverageResults?.topics, peerAverageResults?.topics],
    );

    return topicStatsForAssessmentChart;
};

export default useTopicStatsForAssessmentChart;

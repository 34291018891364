import { useMemo } from 'react';
import { AssessmentResult } from 'src/types/shared/Assessment';
import { BehaviourResult, TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import { chartRotationValues } from 'lib/src/constants/chart';
import {
    BehaviourDataPoints,
    ChartBehaviour,
    ChartTopic,
} from 'lib/src/components/charts/AssessmentChart';

const useTopicsAndBehavioursForAssessmentChart = (
    topicsBehaviors: TopicsBehaviour,
    assessment: AssessmentResult,
    peerAverageResults: AssessmentResult,
) => {
    const topicsForAssessmentChart: ChartTopic[] = useMemo(
        () =>
            [...topicsBehaviors.topics]
                .sort((a, b) => a.sort - b.sort)
                .map(topic => {
                    const percentage =
                        assessment.topics.find(t => t.id === topic.id)?.percentage || 0;

                    return {
                        ...topic,
                        percentage: +percentage.toFixed(0),
                    };
                }),
        [topicsBehaviors.topics, assessment.topics],
    );

    const behavioursForAssessmentChart: ChartBehaviour[] = useMemo(() => {
        const behaviours = [...topicsBehaviors.behaviours]
            .map(behaviour => ({
                ...behaviour,
                sort:
                    topicsForAssessmentChart.find(topic => topic.id === behaviour.topicID)?.sort ||
                    0,
            }))
            .sort((a, b) => a.sort - b.sort || a.topicID - b.topicID || a.id - b.id)
            .map((behaviour, index) => {
                // related self assessment information
                const relatedSelfAssessmentTopic = assessment.topics.find(
                    t => t.id === behaviour.topicID,
                );
                const relatedSelfAssessmentBehaviour = relatedSelfAssessmentTopic?.behaviours.find(
                    b => b.id === behaviour.id,
                );

                let relatedPeerAssessmentBehaviour: BehaviourResult | undefined = undefined;

                if (peerAverageResults) {
                    const relatedPeerAssessmentTopic = peerAverageResults.topics.find(
                        t => t.id === behaviour.topicID,
                    );
                    relatedPeerAssessmentBehaviour = relatedPeerAssessmentTopic?.behaviours.find(
                        b => b.id === behaviour.id,
                    );
                }

                const rawTopic = topicsBehaviors.topics.find(t => t.id === behaviour.topicID);

                return {
                    id: behaviour.id,
                    name: behaviour.name,
                    colour: rawTopic?.colour || '#CCC',
                    colourDark: rawTopic?.colourDark || '#CCC',
                    selfAssessmentPercentage: relatedSelfAssessmentBehaviour?.percentage || 0,
                    peerAssessmentPercentage: relatedPeerAssessmentBehaviour?.percentage || 0,
                    rotationAmount: chartRotationValues[index] || 0,
                };
            });
        return behaviours;
    }, [
        topicsBehaviors.behaviours,
        assessment.topics,
        topicsBehaviors.topics,
        peerAverageResults,
        topicsForAssessmentChart,
    ]);

    const behaviourDataPointsForAssessmentChart: BehaviourDataPoints[] = useMemo(() => {
        const behaviours = [...topicsBehaviors.behaviours]
            .map(behaviour => ({
                ...behaviour,
                sort:
                    topicsForAssessmentChart.find(topic => topic.id === behaviour.topicID)?.sort ||
                    0,
            }))
            .sort((a, b) => a.sort - b.sort || a.topicID - b.topicID || a.id - b.id)
            .map((behaviour, index) => {
                let relatedPeerAssessmentBehaviour: BehaviourResult | undefined = undefined;

                if (peerAverageResults) {
                    const relatedPeerAssessmentTopic = peerAverageResults.topics.find(
                        t => t.id === behaviour.topicID,
                    );
                    relatedPeerAssessmentBehaviour = relatedPeerAssessmentTopic?.behaviours.find(
                        b => b.id === behaviour.id,
                    );
                }

                return {
                    id: behaviour.id,
                    points: [
                        {
                            name: 'Peer assessment',
                            colour: '#eb9822',
                            percentage: relatedPeerAssessmentBehaviour?.percentage || 0,
                        },
                    ],
                };
            });
        return behaviours;
    }, [topicsBehaviors.behaviours, peerAverageResults, topicsForAssessmentChart]);

    return {
        topicsForAssessmentChart,
        behavioursForAssessmentChart,
        behaviourDataPointsForAssessmentChart,
    };
};

export default useTopicsAndBehavioursForAssessmentChart;

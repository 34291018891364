import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AssessmentQuestion } from 'src/types/shared/Assessment';

export const fetchQuestionsShuffledRequest = createAction('fetchQuestionsShuffledRequest');
export const fetchQuestionsShuffledSuccess = createAction<AssessmentQuestion[]>(
    'fetchQuestionsShuffledSuccess',
);
export const fetchQuestionsShuffledFailure = createAction('fetchQuestionsShuffledFailure ');

export const fetchQuestionsShuffled =
    (assessmentCode: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchQuestionsShuffledRequest());
        try {
            const { data } = await api.get<AssessmentQuestion[]>(
                `assessments/questions/${assessmentCode}`,
            );
            dispatch(fetchQuestionsShuffledSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchQuestionsShuffledFailure, e as APIError);
        }
    };

import React, { ReactNode } from 'react';

import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';

const PageHeader: React.FC<PageHeaderProps> = ({
    children,
    tag = 'h1',
    centered = true,
    omitDivider,
}) => {
    const Tag = tag;

    return (
        <FlexWrapper
            justify={centered ? 'center' : 'start'}
            align="center"
            extraClasses="page-header"
        >
            {!omitDivider && <span className="divider"></span>}
            <Tag className="text">{children}</Tag>
        </FlexWrapper>
    );
};

interface PageHeaderProps {
    children: ReactNode;
    tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    centered?: boolean;
    omitDivider?: boolean;
}

export default PageHeader;

import React from 'react';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import LatestAssessmentDetailsInner from './LatestAssessmentDetailsInner';
import useQueryParams from 'lib/src/hooks/useQueryParams';

import { isEmpty } from 'lib/src/utils/generic';
import useAssessmentDetails from './hooks/useAssessmentDetails';

function LatestAssessmentDetails() {
    const URLQuery = useQueryParams();
    const id = URLQuery.get('topic') || '0';

    const { latestAssessmentResults, isFetching, fetchError } = useAssessmentDetails();

    return (
        <DataCheck
            isEmpty={isEmpty(latestAssessmentResults)}
            isLoading={isFetching}
            error={fetchError}
        >
            <LatestAssessmentDetailsInner topicId={id} results={latestAssessmentResults} />
        </DataCheck>
    );
}

export default LatestAssessmentDetails;

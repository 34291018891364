import Modal from 'lib/src/components/modal/Modal';
import Description from 'lib/src/components/typography/Description';
import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';

function AssessmentInfoModal({ closeModal }: Props) {
    return (
        <Modal title="Result" size="small">
            <Description>
                While you wait for feedback, you can view your result. Your result is visible to
                your country or business unit people leader and your leader. From this page you are
                able to compare without names, your results against those of your business unit or
                country, people with the same role as you among other denominations.
            </Description>

            <ButtonRow alignment="center">
                <ActionButton onClick={closeModal}>Close</ActionButton>
            </ButtonRow>
        </Modal>
    );
}

interface Props {
    closeModal: () => void;
}
export default AssessmentInfoModal;

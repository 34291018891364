import { fetchPeerAssessment } from '@actions/peerAssessment/fetchPeerAssessment';
import {
    selectPeerAssessment,
    selectPeerAssessmentsError,
    selectPeerAssessmentsIsFetching,
} from '@selectors/peerAssessments';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useFetchPeerAssessment = (code: string) => {
    const dispatch = useDispatch();
    const peerAssessment = useSelector(selectPeerAssessment);
    const isFetching = useSelector(selectPeerAssessmentsIsFetching);
    const fetchError = useSelector(selectPeerAssessmentsError);

    useEffect(() => {
        dispatch(fetchPeerAssessment(code));
    }, [code, dispatch]);

    return {
        peerAssessment,
        isFetching,
        fetchError,
    };
};

export default useFetchPeerAssessment;

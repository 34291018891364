import React from 'react';

const LogoutButton: React.FC<LogoutButtonProps> = ({ onClick }) => {
    return (
        <button onClick={onClick} aria-label="sign out">
            <i className="far fa-sign-out"></i>
        </button>
    );
};

interface LogoutButtonProps {
    onClick: VoidFunction;
}

export default LogoutButton;

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { LatestAssessment } from '../../../types/shared/Assessment';

export const fetchPeerAssessmentRequest = createAction('fetchPeerAssessmentRequest');
export const fetchPeerAssessmentSuccess = createAction<LatestAssessment>(
    'fetchPeerAssessmentSuccess',
);
export const fetchPeerAssessmentFailure = createAction('fetchPeerAssessmentFailure');

export const fetchPeerAssessment =
    (code: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchPeerAssessmentRequest());
        try {
            const { data } = await api.get<LatestAssessment>(`peer/assessment/${code}`);
            dispatch(fetchPeerAssessmentSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchPeerAssessmentFailure, e as APIError);
        }
    };

import { Link, useParams } from 'react-router-dom';

import Pod from '@components/pods/Pod';
import PageDescription from '@components/typography/PageDescription';
import ActionButton from 'lib/src/components/button/ActionButton';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Meta from 'lib/src/components/meta/Meta';
import Title from 'lib/src/components/typography/Title';
import CompletionBackgroundImage from '../../_content/completion-background.jpeg';
import SecondaryBackground from '@components/secondaryBackground/SecondaryBackground';

const CheckPeerResults = () => {
    const { code } = useParams<{ code: string }>();

    return (
        <FlexWrapper
            id="check-results"
            direction="column"
            align="center"
            extraClasses="full-page area-padding"
        >
            <Meta title="Assessment Complete" />
            <SecondaryBackground
                src={CompletionBackgroundImage}
                alt="People raising hands cheerfully on a canoe"
            />

            <FlexWrapper direction="column" align="center" extraClasses="greeting-pod">
                <Pod
                    extraStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        marginBottom: '30px',
                    }}
                >
                    <Title>Thank you</Title>

                    <PageDescription>
                        Thank you for completing this peer assessment survey. For a comprehensive
                        overview and graph of your answers, head to the desktop version of this
                        survey. Your answers will appear anonymously throughout the rest of the
                        company attached to your role title.
                    </PageDescription>

                    <FlexWrapper direction="column" align="center">
                        <Link
                            to={`/peer/results/${code}`}
                            style={{ textDecoration: 'none', width: '90%' }}
                        >
                            <ActionButton source="positive" autoWidth={false} width={12}>
                                View results
                            </ActionButton>
                        </Link>
                    </FlexWrapper>
                </Pod>
            </FlexWrapper>
        </FlexWrapper>
    );
};

export default CheckPeerResults;

import React from 'react';
import { Direction, Range } from 'react-range';

import { FormInputProps } from 'lib/src/types/shared/FormInputProps';

const directionObj = {
    down: Direction.Down,
    up: Direction.Up,
};

const RangeSlider: React.FC<RangeSliderProps> = ({
    value,
    name,
    onChange,
    min = 1,
    max = 5,
    step = 0.5,
    showHeatMapTrack,
    pointsArray,
    hideThumb = false,
    direction = 'down',
}) => {
    const amountOfPoints = Math.floor((max - min) / step);
    const points = !!pointsArray ? pointsArray : [...Array(amountOfPoints).keys()];
    const pointDirection = directionObj[direction];

    return (
        <Range
            values={value}
            onChange={value => onChange(name, value)}
            min={min}
            max={max}
            step={step}
            direction={pointDirection}
            renderTrack={({ props, children }) => (
                <div
                    {...props}
                    style={{ ...props.style }}
                    className={`range-slider-track ${showHeatMapTrack ? 'heat-track' : ''}`}
                >
                    {points.map((_, i) => {
                        const percentageFromTop = (100 / amountOfPoints) * i;

                        return (
                            <React.Fragment key={i}>
                                {i === 0 ? (
                                    <div
                                        className="point"
                                        style={{
                                            top: `${percentageFromTop}%`,
                                            right: '50%',
                                            width: '32.5px',
                                            background:
                                                'linear-gradient(90deg, rgba(0,72,118,1) 70%, rgba(149,196,237,1) 82%)',
                                        }}
                                    />
                                ) : i === amountOfPoints ? (
                                    <div
                                        className="point"
                                        style={{
                                            top: '99%',
                                            right: '50%',
                                            width: '32.5px',
                                            background:
                                                'linear-gradient(90deg, rgba(6,77,123,1) 70%, rgba(31,0,92,1) 82%)',
                                        }}
                                    />
                                ) : (
                                    <div
                                        className="point"
                                        style={{ top: `${percentageFromTop}%` }}
                                    />
                                )}
                            </React.Fragment>
                        );
                    })}
                    {children}
                </div>
            )}
            renderThumb={({ props }) => {
                const { key, ...rest } = props;

                return (
                    <div
                        {...rest}
                        className="range-slider-thumb"
                        style={
                            hideThumb
                                ? { ...props.style, display: 'none' }
                                : { ...props.style, display: 'flex' }
                        }
                    />
                );
            }}
            renderMark={({ index }) => {
                const percentageFromTop = (100 / amountOfPoints) * index;
                const point = points[index];

                const value = typeof point !== 'number' ? point?.text : null;

                return (
                    <p
                        className="text"
                        style={
                            index < amountOfPoints
                                ? { top: `calc(${percentageFromTop}% - 10px) ` }
                                : { bottom: 0 }
                        }
                    >
                        {value}
                    </p>
                );
            }}
        />
    );
};

interface RangeSliderProps extends FormInputProps<number[]> {
    min?: number;
    max?: number;
    step?: number;
    showHeatMapTrack?: boolean;
    pointsArray?: { text: string }[];
    hideThumb?: boolean;
    direction?: 'up' | 'down';
}

export default RangeSlider;

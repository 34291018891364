import React, { ReactNode } from 'react';

const PageDescription: React.FC<PageDescriptionProps> = ({
    children,
    extraClasses = '',
    centered = true,
    source = 'primary',
}) => (
    <p className={`page-description ${centered ? 'centered' : ''} ${source} ${extraClasses}`}>
        {children}
    </p>
);

interface PageDescriptionProps {
    children: ReactNode;
    extraClasses?: string;
    centered?: boolean;
    source?: 'primary' | 'secondary';
}

export default PageDescription;

import useWindowDimensions from 'lib/src/hooks/useWindowDimensions';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const handleResetScroll = (windowHeight: number) => {
    const body = document.getElementById('web-wrapper');
    if (body && windowHeight && windowHeight < 700) body.scrollTo(0, 0);
};

const useResetScrollOnNav = () => {
    const { pathname } = useLocation();
    const { height } = useWindowDimensions();

    useEffect(() => {
        handleResetScroll(height);
    }, [pathname, height]);
};

export default useResetScrollOnNav;

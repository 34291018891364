import { useMemo } from 'react';
import { CompareAssessmentResult } from 'src/types/shared/Assessment';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import { BehaviourDataPoints, ChartTopic } from 'lib/src/components/charts/AssessmentChart';
import { DATA_POINT_COLOURS } from 'lib/src/constants/chart';

const useBehaviourDataPointsForCompareChart = (
    topicsBehaviors: TopicsBehaviour,
    topicsForAssessmentChart: ChartTopic[],
    compareResults: CompareAssessmentResult,
    averageScoreLabel: string,
) => {
    const behaviourDataPointsForCompareChart: BehaviourDataPoints[] = useMemo(() => {
        const behaviours = [...topicsBehaviors.behaviours]
            .map(behaviour => ({
                ...behaviour,
                sort:
                    topicsForAssessmentChart.find(topic => topic.id === behaviour.topicID)?.sort ||
                    0,
            }))
            .sort((a, b) => a.sort - b.sort || a.topicID - b.topicID || a.id - b.id)
            .map(behaviour => {
                const relatedCompareTopic = compareResults.topicResults.find(
                    topic => topic.id === behaviour.topicID,
                );
                const relatedCompareBehaviour = relatedCompareTopic?.behaviours.find(
                    b => b.id === behaviour.id,
                );

                return {
                    id: behaviour.id,
                    points: [
                        {
                            name: 'Zone of excellence',
                            colour: DATA_POINT_COLOURS.ZONE_OF_EXCELLENCE,
                            percentage: 95,
                        },
                        {
                            name: averageScoreLabel || 'Average score',
                            colour: DATA_POINT_COLOURS.SECOND,
                            percentage: relatedCompareBehaviour?.percentage || 0,
                        },
                    ],
                };
            });
        return behaviours;
    }, [
        topicsBehaviors.behaviours,
        compareResults.topicResults,
        topicsForAssessmentChart,
        averageScoreLabel,
    ]);

    return behaviourDataPointsForCompareChart;
};

export default useBehaviourDataPointsForCompareChart;

import { RootState } from '@reducers/index';
import { AssessmentQuestion } from 'src/types/shared/Assessment';

export const getPeerAssessmentQuestions = (state: RootState): Record<number, AssessmentQuestion> =>
    state.peerAssessmentQuestionsReducer.peerAssessmentQuestions;
export const getPeerAssessmentQuestionsArr = (state: RootState): AssessmentQuestion[] =>
    Object.values(state.peerAssessmentQuestionsReducer.peerAssessmentQuestions);

export const getPeerAssessmentQuestionsIsFetching = (state: RootState): boolean =>
    state.peerAssessmentQuestionsReducer.isFetching;
export const getPeerAssessmentQuestionsFetchError = (state: RootState): string | null =>
    state.peerAssessmentQuestionsReducer.fetchError;
export const getPeerAssessmentQuestionFetchSuccess = (state: RootState): boolean =>
    state.peerAssessmentQuestionsReducer.fetchSuccess;

const ProgressBar: React.FC<ProgressBarProps> = ({ completed }) => {
    const bgcolor =
        completed > 90
            ? 'green'
            : completed > 75
            ? '#45a14d'
            : completed > 50
            ? '#ccbc06'
            : completed > 30
            ? 'orange'
            : 'red';

    return (
        <div className="progress-bar-container">
            <div
                style={{
                    width: `${completed > 5 ? completed : 6}%`,
                    backgroundColor: bgcolor,
                }}
                className="progress-bar"
                role="progressbar"
                aria-valuenow={completed}
                aria-valuemin={0}
                aria-valuemax={100}
            ></div>
            <span className="text">{`${completed}%`}</span>
        </div>
    );
};

interface ProgressBarProps {
    completed: number;
}

export default ProgressBar;

import { Config } from '.';

const config: Config = {
    //API_URL: 'http://localhost:5001',
    API_URL: 'https://api.decathlon.madeatsilverchip.com',
    OAUTH_URL: 'https://preprod.idpdecathlon.oxylane.com/as/authorization.oauth2',
    OAUTH_CLIENT_ID: 'C2fae63789d8368d06545810d9e7abbf4836f0802',
    OAUTH_REDIRECT_URL: 'https://localhost:3000',
    OAUTH_ACCESS_TOKEN_URL: 'https://preprod.idpdecathlon.oxylane.com/as/token.oauth2',
};

export default config;

import React, { CSSProperties } from 'react';

const PaginationDots: React.FC<PaginationDotsProps> = ({ length, currentPage, color }) => {
    const dots: number[] = Array(length)
        .fill(0)
        .map((_: number, i: number) => i + 1);

    const lineStyle: CSSProperties = {
        width: `calc(100% / ${length} * ${currentPage - 1})`,
    };
    if (!!color) lineStyle.backgroundColor = color;
    return (
        <div className="pagination-dots-container">
            <div className="pagination-dots">
                <div className="line" role="presentation" aria-label="line" />
                <div
                    style={lineStyle}
                    className="line prev"
                    role="presentation"
                    aria-label="line prev"
                />
                {dots.map((dot: number) => {
                    const classes = `${
                        currentPage === dot ? 'selected' : currentPage > dot ? 'prev' : ''
                    }`;
                    const dotStyle =
                        currentPage >= dot && !!color
                            ? {
                                  backgroundColor: color,
                              }
                            : undefined;
                    return (
                        <span
                            style={dotStyle}
                            className={`dot ${classes} `}
                            key={dot}
                            role="presentation"
                            aria-label={`Dot ${dot}`}
                        />
                    );
                })}
            </div>
        </div>
    );
};

interface PaginationDotsProps {
    length: number;
    currentPage: number;
    color?: string;
}

export default PaginationDots;

import { Switch, Route } from 'react-router-dom';

import Home from '@pages/home/Home';
import AuthRoutes from './AuthRoutes';
import SubRouter from './SubRouter';
import DefaultRedirect from './DefaultRedirect';
import LatestAssessment from '@pages/latestAssessment/LatestAssessment';

import Assessment from '@pages/assessment/Assessment';
import PeerInvitation from '@pages/peerInvitation/PeerInvitation';
import CheckResults from '@pages/assessment/CheckResults';
import CompleteAssessment from '@pages/assessment/CompleteAssessment';
import PeerRoutes from '@routes/PeerRoutes';
import LatestAssessmentDetails from '@pages/latestAssessmentDetails/LatestAssessmentDetails';
import CompareRoutes from './CompareRoutes';
import AssessmentInfo from '@pages/assessmentInfo/AssessmentInfo';

const Routes = () => (
    <Switch>
        <Route exact path="/">
            <Home />
        </Route>
        <SubRouter path="/auth">
            <AuthRoutes />
        </SubRouter>
        <SubRouter auth exact path="/latest-assessment">
            <LatestAssessment />
        </SubRouter>
        <SubRouter auth exact path="/latest-assessment/details">
            <LatestAssessmentDetails />
        </SubRouter>
        <SubRouter auth exact path="/assessment-info">
            <AssessmentInfo />
        </SubRouter>
        <SubRouter auth exact path="/assessment">
            <Assessment />
        </SubRouter>
        <SubRouter auth exact path="/complete-assessment/:assessmentCode">
            <CompleteAssessment />
        </SubRouter>
        <SubRouter auth exact path="/check-results/:assessmentCode">
            <CheckResults />
        </SubRouter>
        <Route exact path="/peer-invitation/:assessmentCode">
            <PeerInvitation />
        </Route>
        <Route path="/peer">
            <PeerRoutes />
        </Route>
        <SubRouter auth path="/compare">
            <CompareRoutes />
        </SubRouter>
        <DefaultRedirect to="/" />
    </Switch>
);

export default Routes;

import { useDispatch } from 'react-redux';

import LinkButton from 'lib/src/components/button/LinkButton';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import PageHeader from '@components/typography/PageHeader';
import Pod from '@components/pods/Pod';
import { createNewAssessment } from '@actions/assessment/createNewAssessment';
import { Assessment, LatestAssessmentResultsResponse } from 'src/types/shared/Assessment';
import { fetchLatestAssessment } from '@actions/assessment';
import { Topic, TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';

import LatestAssessmentResults from './LatestAssessmentResults';
import useWindowDimensions from 'lib/src/hooks/useWindowDimensions';
import { mediaQueriesMobileFirst } from 'lib/src/constants/mediaQueries';
import AssessmentPod from './AssessmentPod';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import { Link, useHistory } from 'react-router-dom';
import BubbleHeader from '@components/header/BubbleHeader';
import ActionButton from 'lib/src/components/button/ActionButton';

const LatestAssessmentInner: React.FC<LatestAssessmentInnerProps> = ({
    latestAssessment,
    topicsBehaviours,
    latestAssessmentResults,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useIsLoggedIn();
    const windowSize = useWindowDimensions();
    const topics = topicsBehaviours?.topics || [];

    const { canGetMyTypeComparison, canGetOneLevelUpComparison } = latestAssessmentResults;
    const shouldShowCompareButton = canGetMyTypeComparison || canGetOneLevelUpComparison;

    return (
        <div className="full-page">
            <BubbleHeader overlapBottom curveHeight="large">
                <PageHeader>
                    <p style={{ fontSize: '16px', color: 'white' }}>
                        Hello, <strong>{id?.givenName}</strong>
                    </p>
                    {latestAssessmentResults && (
                        <p style={{ fontSize: '16px', color: 'white' }}>
                            Below is your latest assessment
                        </p>
                    )}
                </PageHeader>
            </BubbleHeader>
            <FlexWrapper
                direction="column"
                justify="between"
                extraClasses="area-padding"
                style={{ paddingTop: 0, marginTop: '-20px' }}
            >
                {windowSize?.width && windowSize?.width < mediaQueriesMobileFirst.mdDesktop ? (
                    <FlexWrapper
                        direction="column"
                        align="center"
                        justify="between"
                        extraClasses="graph-pods-wrapper"
                    >
                        <FlexWrapper align="center" justify="evenly" wrap="wrap">
                            {topics.map((topic: Topic, i) => {
                                return (
                                    <Link
                                        to={`/latest-assessment/details?topic=${topic.id}`}
                                        key={`assessment-pod-${topic.id}`}
                                        style={{ width: `50%` }}
                                        className={`assessment-pod-link ${
                                            i < topics.length - 2 ? '' : 'bottom-row'
                                        }`}
                                    >
                                        <AssessmentPod
                                            title={topic.name}
                                            colour={topic.colour}
                                            colourDark={topic.colourDark}
                                            topicId={topic.id}
                                        />
                                    </Link>
                                );
                            })}
                        </FlexWrapper>
                    </FlexWrapper>
                ) : (
                    <>
                        <Pod
                            extraStyle={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px',
                            }}
                        >
                            {latestAssessmentResults && (
                                <LatestAssessmentResults
                                    latestAssessmentResults={latestAssessmentResults}
                                    topicsBehaviours={topicsBehaviours}
                                />
                            )}
                        </Pod>
                    </>
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '30px',
                        flexWrap: 'wrap',
                    }}
                >
                    {latestAssessment.isCompleted ? (
                        <ActionButton
                            onClick={() => {
                                dispatch(createNewAssessment());
                                setTimeout(() => history.push('/assessment-info'), 500);
                            }}
                            source="positive"
                            extraClasses="assessment-button"
                        >
                            Begin new assessment
                        </ActionButton>
                    ) : (
                        <ActionButton
                            onClick={() => {
                                dispatch(fetchLatestAssessment(latestAssessment.assessmentCode));
                                setTimeout(() => history.push('/assessment'), 500);
                            }}
                            source="positive"
                            extraClasses="assessment-button"
                        >
                            Continue assessment
                        </ActionButton>
                    )}
                    <LinkButton
                        href={`/peer-invitation/${latestAssessment.assessmentCode}`}
                        source="primary"
                        extraClasses="assessment-button"
                    >
                        Invite Peers
                    </LinkButton>
                    {shouldShowCompareButton && (
                        <LinkButton
                            extraClasses="assessment-button"
                            href="/compare"
                            source="secondary"
                        >
                            Compare Results
                        </LinkButton>
                    )}
                </div>
                {!shouldShowCompareButton && (
                    <FlexWrapper justify="center">
                        <p style={{ fontSize: '16px' }}>
                            We don't have enough data to show comparisons yet. Please check back
                            later to compare your results.
                        </p>
                    </FlexWrapper>
                )}
            </FlexWrapper>
        </div>
    );
};

interface LatestAssessmentInnerProps {
    latestAssessment: Assessment;
    topicsBehaviours: TopicsBehaviour;
    latestAssessmentResults: LatestAssessmentResultsResponse;
}

export default LatestAssessmentInner;

import Carousel from '@components/carousel/Carousel';
import Meta from 'lib/src/components/meta/Meta';
import React, { useState } from 'react';
import { AssessmentResultsResponse } from 'src/types/shared/Assessment';
import AssessmentBehaviourDetailsPod from './AssessmentBehaviourDetailsPod';
import { BehaviourResult } from 'src/types/shared/TopicsBehaviors';
import { useSelector } from 'react-redux';
import { RootState } from '@reducers/index';
import { getTopicById } from '@selectors/topicsBehaviors';
import BubbleHeader from '@components/header/BubbleHeader';
import PaginationDots from '@components/paginationDots/PaginationDots';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';

const LatestAssessmentDetailsInner: React.FC<LatestAssessmentDetailsInnerProps> = ({
    topicId,
    results,
}) => {
    const topicInfo = useSelector((state: RootState) => getTopicById(state, +topicId));
    const [currentPage, setCurrentPage] = useState<number>(1);

    if (!results) return null;

    const {
        leaderResults: { topics = [] },
        peerAverageResults,
    } = results;

    const thisTopic = topics.find(t => t.id === +topicId);
    const thisPeerTopic = peerAverageResults.topics.find(t => t.id === +topicId);
    const { name, colour, colourDark } = topicInfo;
    const paginationLength = thisTopic?.behaviours?.length || 0;

    const bgStyle = {
        background: `linear-gradient(to bottom, #CFE2F3 0%, ${colour} 100%)`,
    };

    return (
        <FlexWrapper
            id="latest-assessment-details"
            direction="column"
            extraClasses="full-page flex-column"
            style={bgStyle}
        >
            <Meta title="Your Results" />

            <BubbleHeader color={colourDark}>
                <h2 className="page-title">Your Results</h2>
                <h3 className="page-title subtitle">{name}</h3>
                <PaginationDots
                    color={colour}
                    length={paginationLength}
                    currentPage={currentPage}
                />
            </BubbleHeader>
            <Carousel onChange={i => setCurrentPage(i + 1)}>
                {(thisTopic?.behaviours || []).map((b: BehaviourResult, i: number) => {
                    const thisPeerBehaviour = thisPeerTopic?.behaviours.find(pB => pB.id === b.id);
                    return (
                        <AssessmentBehaviourDetailsPod
                            key={i}
                            behaviour={b}
                            index={i}
                            colour={colour}
                            colourDark={colourDark}
                            peerBehaviour={thisPeerBehaviour}
                        />
                    );
                })}
            </Carousel>
        </FlexWrapper>
    );
};

interface LatestAssessmentDetailsInnerProps {
    topicId: string;
    results: AssessmentResultsResponse | null;
}

export default LatestAssessmentDetailsInner;

import Meta from 'lib/src/components/meta/Meta';
import LinkButton from 'lib/src/components/button/LinkButton';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Pod from '@components/pods/Pod';
import PageHeader from '@components/typography/PageHeader';
import PageDescription from '@components/typography/PageDescription';
import BackgroundImage from 'lib/src/_content/background-image.png';
import BackgroundImageSmall from 'lib/src/_content/background-image-small.jpeg';
import BackgroundImageLong from 'lib/src/_content/background-long.jpeg';
import useWindowDimensions from 'lib/src/hooks/useWindowDimensions';
import { mediaQueriesMobileFirst } from 'lib/src/constants/mediaQueries';
import { Redirect, useLocation } from 'react-router-dom';
import { OAuthPopup } from '@tasoskakour/react-use-oauth2';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import useAssessmentDataFetch from '@pages/assessment/hooks/useAssessmentDataFetch';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';

const Home: React.FC = (): JSX.Element => {
    const windowSize = useWindowDimensions();
    const { id } = useIsLoggedIn();
    const userName = id?.displayName ?? '';

    const search = useLocation().search;
    const params = decodeURI(search)
        .replace('?', '')
        .split('&')
        .map(param => param.split('='))
        .reduce<Record<string, string>>((values, [key, value]) => {
            values[key] = value;
            return values;
        }, {});
    const { code } = params;

    const { latestAssessment, isLoading, isInProgress } = useAssessmentDataFetch();

    const hasResults = latestAssessment?.isComplete || false;

    if (hasResults) return <Redirect to="/latest-assessment" />;

    if (!!code) {
        return <OAuthPopup />;
    }

    return (
        <DataCheck isLoading={isLoading}>
            <FlexWrapper id="home-page" direction="column" extraClasses="full-page area-padding">
                <Meta title="Home" />
                <img
                    className="background"
                    alt="People running with excitement"
                    src={
                        windowSize?.width && windowSize?.width < mediaQueriesMobileFirst.lsTablet
                            ? BackgroundImageSmall
                            : windowSize?.width &&
                              windowSize?.width < mediaQueriesMobileFirst.lgDesktop
                            ? BackgroundImageLong
                            : BackgroundImage
                    }
                />

                <Pod>
                    {userName.length <= 9 ? (
                        <PageHeader>
                            Hello, <strong>{userName}</strong>
                        </PageHeader>
                    ) : (
                        <FlexWrapper align="center" extraClasses="page-header">
                            <span className="divider long"></span>
                            <FlexWrapper
                                direction="column"
                                align="center"
                                extraClasses="page-header long"
                            >
                                <p>Hello, </p>
                                <p>
                                    <strong>{userName}</strong>
                                </p>
                            </FlexWrapper>
                        </FlexWrapper>
                    )}
                    <PageDescription>
                        Welcome to the leadership model assessment. <br />
                        To {!isInProgress ? 'begin' : 'continue'}, simply click the button below.
                    </PageDescription>

                    <LinkButton
                        href={!isInProgress ? '/assessment-info' : 'assessment'}
                        source="positive"
                    >
                        {!isInProgress ? "I'm ready!" : 'Continue'}
                    </LinkButton>
                </Pod>
            </FlexWrapper>
        </DataCheck>
    );
};

export default Home;

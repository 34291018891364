import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const fetchPeerInvitationRequest = createAction('fetchPeerInvitationRequest');
export const fetchPeerInvitationSuccess = createAction<string[]>('fetchPeerInvitationSuccess');
export const fetchPeerInvitationFailure = createAction('fetchPeerInvitationFailure');

export const fetchPeerInvitation =
    (id: number) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchPeerInvitationRequest());
        try {
            const { data } = await api.get<string[]>(`assessments/peerInvites/${id}`);
            dispatch(fetchPeerInvitationSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchPeerInvitationFailure, e as APIError);
        }
    };

import Pod from '@components/pods/Pod';
import Title from 'lib/src/components/typography/Title';
import ComposedChart, {
    BarProps,
    LineProps,
    ReferenceLineProps,
} from 'lib/src/components/charts/ComposedChart';
import LinkButton from 'lib/src/components/button/LinkButton';
import { assessmentChartKey } from './constants';
import { useSelector } from 'react-redux';
import { getAssessmentResultsSummaryByTopicId } from '@selectors/assessment';
import { RootState } from '@reducers/index';
import { BehaviourResult } from 'src/types/shared/TopicsBehaviors';

const AssessmentPod: React.FC<AssessmentPodProps> = ({ title, colour, colourDark, topicId }) => {
    const topicSummary = useSelector((state: RootState) =>
        getAssessmentResultsSummaryByTopicId(state, topicId),
    );

    const data = topicSummary.leaderBehaviours?.map((leaderBehaviour: BehaviourResult) => {
        const thisPeerBehaviour =
            topicSummary.peerAverageBehaviours?.find(
                (pB: BehaviourResult) => pB.id === leaderBehaviour.id,
            ) || ({} as BehaviourResult);
        return {
            [assessmentChartKey.PEER]: thisPeerBehaviour.percentage || 0,
            [assessmentChartKey.SELF]: leaderBehaviour.percentage || 0,
        };
    });

    const bars: BarProps[] = [
        {
            dataKey: assessmentChartKey.SELF,
            fill: colourDark,
        },
    ];

    const referenceLines: ReferenceLineProps[] = [
        {
            value: topicSummary.leaderPercentage || 0,
            stroke: '#004876',
        },
        {
            value: 95,
            stroke: '#02D196',
        },
    ];

    const lines: LineProps[] = [
        {
            dataKey: assessmentChartKey.PEER,
            stroke: '#FF600A',
        },
    ];

    return (
        <Pod extraClasses="assessment-pod">
            <div style={{ height: '50%' }}>
                <div
                    className="assessment-percentage-result"
                    style={{ backgroundColor: colourDark }}
                >
                    <p>{Math.round(topicSummary.leaderPercentage)}%</p>
                </div>

                <div className="title-wrapper">
                    <Title>{title}</Title>
                </div>
            </div>

            <div style={{ height: '50%' }} className="flex-column justify-between">
                {data?.length ? (
                    <ComposedChart
                        data={data}
                        bars={bars}
                        lines={lines}
                        referenceLines={referenceLines}
                        showTooltip
                        layout="vertical"
                        XAxisTicks={false}
                        YAxisTicks={false}
                        radius={[0, 50, 50, 0]}
                        XDomain={[0, 100]}
                    />
                ) : (
                    <div>
                        <LinkButton href="">Add self assessment</LinkButton>
                    </div>
                )}
            </div>
        </Pod>
    );
};

interface AssessmentPodProps {
    title: React.ReactNode;
    colour: string;
    colourDark: string;
    // leaderTopic: TopicResult;
    topicId: number;
}

export default AssessmentPod;

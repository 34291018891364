import { useSelector } from 'react-redux';
import { getLatestAssessmentResultsIsFetching } from '@selectors/assessment';
import AssessmentChart from 'lib/src/components/charts/AssessmentChart';
import useTopicsAndBehavioursForAssessmentChart from './hooks/useTopicsAndBehavioursForAssessmentChart';
import { AssessmentResultsResponse } from 'src/types/shared/Assessment';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import useBehaviourDataPointsForAssessmentChart from './hooks/useBehaviourDataPointsForAssessmentChart';
import useTopicStatsForAssessmentChart from './hooks/useTopicStatsForAssessmentChart';

const LatestAssessmentResults: React.FC<LatestAssessmentResultsProps> = ({
    latestAssessmentResults,
    topicsBehaviours,
}) => {
    const isFetching = useSelector(getLatestAssessmentResultsIsFetching);

    const { topicsForAssessmentChart, behavioursForAssessmentChart } =
        useTopicsAndBehavioursForAssessmentChart(
            topicsBehaviours,
            latestAssessmentResults.leaderResults,
            latestAssessmentResults.peerAverageResults,
        );

    const behaviourDataPointsForAssessmentChart = useBehaviourDataPointsForAssessmentChart(
        topicsBehaviours,
        latestAssessmentResults.peerAverageResults,
        latestAssessmentResults.leaderAverageResults,
        latestAssessmentResults.teamAverageResults,
        topicsForAssessmentChart,
    );

    const topicStatsForAssessmentChart = useTopicStatsForAssessmentChart(
        topicsForAssessmentChart,
        latestAssessmentResults.peerAverageResults,
        latestAssessmentResults.leaderAverageResults,
    );

    if (isFetching) return <i className="fa fa-spinner fa-spin"></i>;
    if (!latestAssessmentResults) return null;

    return (
        <AssessmentChart
            topics={topicsForAssessmentChart}
            behaviours={behavioursForAssessmentChart}
            dataPoints={behaviourDataPointsForAssessmentChart}
            chartStats={topicStatsForAssessmentChart}
            isSmallVersion
        />
    );
};

interface LatestAssessmentResultsProps {
    latestAssessmentResults: AssessmentResultsResponse;
    topicsBehaviours: TopicsBehaviour;
}

export default LatestAssessmentResults;

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Assessment } from 'src/types/shared/Assessment';

export const postCompletedAssessmentRequest = createAction('postCompletedAssessmentRequest');
export const postCompletedAssessmentSuccess = createAction<Assessment>(
    'postCompletedAssessmentSuccess',
);
export const postCompletedAssessmentFailure = createAction('postCompletedAssessmentFailure ');

export const postCompletedAssessment =
    (assessmentCode: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postCompletedAssessmentRequest());
        try {
            const { data }: PostCompletedAssessmentResponse = await api.post(
                `assessments/complete`,
                { assessmentCode: assessmentCode },
            );
            dispatch(postCompletedAssessmentSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postCompletedAssessmentFailure, e as APIError);
        }
    };

interface PostCompletedAssessmentResponse {
    data: Assessment;
}

import React, { SyntheticEvent } from 'react';
import Pod from '@components/pods/Pod';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import { useSwiper } from 'swiper/react';
import { BehaviourResult } from 'src/types/shared/TopicsBehaviors';
import AssessmentDetailsChart, {
    BarProps,
    ReferenceLineProps,
} from 'lib/src/components/charts/AssessmentDetailsChart';
import { useSelector } from 'react-redux';
import { getBehaviorById } from '@selectors/topicsBehaviors';
import { RootState } from '@reducers/index';
import { getPeerResultsByBehaviourId } from '@selectors/assessment';
import { QuestionResult } from 'src/types/shared/Question';
import { DATA_POINT_COLOURS } from 'lib/src/constants/chart';

const AssessmentBehaviourDetailsPod: React.FC<AssessmentBehaviourDetailsPodProps> = ({
    behaviour,
    index,
    colour,
    colourDark,
    peerBehaviour,
}) => {
    const swiper = useSwiper();
    const isFirst = index === 0;
    const isLast = index === swiper.slides.length - 1;
    const thisBehaviour = useSelector((state: RootState) => getBehaviorById(state, behaviour.id));
    const peerResults =
        useSelector((state: RootState) => getPeerResultsByBehaviourId(state, behaviour.id)) || [];
    const peerPercentage = peerBehaviour?.percentage || 0;
    const targetPercentage = 95;

    const data = [
        {
            name: 'My score',
            score: Math.round((behaviour.percentage || 0) as number),
        },
        ...peerResults
            .filter(({ answer }: QuestionResult) => !Number.isNaN(answer))
            .map(({ answer }: QuestionResult, i: number) => ({
                name: i === 0 ? 'Peers' : '',
                score: answer ? Math.round(answer * (100 / 9)) : 0,
            })),
    ];

    const bars: BarProps[] = [
        {
            dataKey: 'score',
            fill: colour,
        },
    ];

    const referenceLines: ReferenceLineProps[] = [
        {
            label: 'Self assessment',
            value: Math.round(behaviour.percentage || 0),
            stroke: DATA_POINT_COLOURS.SECOND,
        },
        {
            label: 'Peer assessment',
            value: Math.round(peerPercentage || 0),
            stroke: DATA_POINT_COLOURS.THIRD,
        },
        {
            label: 'Zone of excellence',
            value: targetPercentage,
            stroke: DATA_POINT_COLOURS.ZONE_OF_EXCELLENCE,
        },
    ];

    const dataHeight = data.length * 30;

    return (
        <Pod extraClasses="peer-assessment-pod">
            <FlexWrapper width={12} direction="row" align="start" justify="center">
                <div className="button-area">
                    <button
                        disabled={isFirst}
                        className="pod-button"
                        data-testid="chevron-left-button"
                        onClick={(e: SyntheticEvent) => {
                            e.stopPropagation();
                            swiper.slidePrev();
                        }}
                    >
                        <i className="far fa-chevron-left"></i>
                    </button>
                </div>
                <div className="title-container">
                    <h2 className="title">{thisBehaviour?.name}</h2>
                </div>
                <div className="button-area">
                    <button
                        disabled={isLast}
                        className="pod-button"
                        data-testid="chevron-right-button"
                        onClick={(e: SyntheticEvent) => {
                            e.stopPropagation();
                            swiper.slideNext();
                        }}
                    >
                        <i className="far fa-chevron-right"></i>
                    </button>
                </div>
            </FlexWrapper>
            <div className="chart-scroll-wrapper">
                {data.length ? (
                    <AssessmentDetailsChart
                        data={data}
                        bars={bars}
                        referenceLines={referenceLines}
                        showTooltip
                        layout="vertical"
                        radius={[0, 50, 50, 0]}
                        containerClasses="behaviour-result-chart-container"
                        minHeight={`${dataHeight + 50}px`}
                        colour={colour}
                        colourDark={colourDark}
                    />
                ) : (
                    <div>NO DATA</div>
                )}
            </div>
        </Pod>
    );
};

interface AssessmentBehaviourDetailsPodProps {
    index: number;
    behaviour: BehaviourResult;
    colour: string;
    colourDark: string;
    peerBehaviour?: BehaviourResult;
}

export default AssessmentBehaviourDetailsPod;

import LatestAssessmentInner from './LatestAssessmentInner';
import useFetchMyAssessments from '@pages/home/hooks/useFetchMyAssessments';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import useFetchTopicsBehaviors from '@pages/assessment/hooks/useFetchTopicsBehaviors';
import useFetchLatestAssessmentResults from './hooks/useFetchLatestAssessmentResults';
import { isEmpty } from 'lib/src/utils/generic';
import { LatestAssessmentResultsResponse } from 'src/types/shared/Assessment';
import Meta from 'lib/src/components/meta/Meta';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import AssessmentInfoModal from '@pages/latestAssessment/modals/AssessmentInfoModal';
import useLocalStorage from 'lib/src/hooks/useLocalStorage';
import dayjs from 'dayjs';

const LatestAssessment = () => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [timeModalShown, setTimeModalShown] = useLocalStorage('timeModalShown', null);

    const {
        latestAssessment,
        isFetching: isFetchingAssessments,
        fetchError: assessmentsFetchError,
    } = useFetchMyAssessments();
    const {
        topicsBehaviors,
        isFetching: isFetchingTopicsBehaviours,
        fetchError: topicsBehavioursFetchError,
    } = useFetchTopicsBehaviors();

    const {
        latestAssessmentResults,
        isFetching: isFetchingLatestResults,
        fetchError: latestResultsFetchError,
    } = useFetchLatestAssessmentResults();

    const hasData = !isEmpty(latestAssessment) || !isEmpty(latestAssessmentResults);

    useEffect(() => {
        if (!latestAssessmentResults?.peerResults.length) {
            const parsedTimestamp = +timeModalShown;
            if (dayjs(Date.now()).diff(parsedTimestamp, 'minute') > 30) {
                const timestamp = Date.now();
                setShowModal(true);
                setTimeModalShown(timestamp.toString());
            }
        }
    }, [latestAssessmentResults, setTimeModalShown, timeModalShown]);

    if (!hasData) {
        history.push('/');
    }

    return (
        <>
            {showModal && <AssessmentInfoModal closeModal={() => setShowModal(false)} />}

            <Meta title="Latest Assessment" />
            <DataCheck
                isEmpty={
                    isEmpty(latestAssessment) ||
                    isEmpty(topicsBehaviors) ||
                    isEmpty(latestAssessmentResults)
                }
                isLoading={
                    isFetchingAssessments || isFetchingTopicsBehaviours || isFetchingLatestResults
                }
                error={
                    assessmentsFetchError || topicsBehavioursFetchError || latestResultsFetchError
                }
            >
                <LatestAssessmentInner
                    latestAssessment={latestAssessment}
                    topicsBehaviours={topicsBehaviors}
                    latestAssessmentResults={
                        latestAssessmentResults as LatestAssessmentResultsResponse
                    }
                />
            </DataCheck>
        </>
    );
};

export default LatestAssessment;

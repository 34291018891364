import { fetchPeerAssessmentInfo } from '@actions/peerAssessment/fetchPeerAssessmentInfo';
import {
    selectPeerAssessmentInfo,
    selectPeerAssessmentsError,
    selectPeerAssessmentsIsFetching,
} from '@selectors/peerAssessments';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useFetchPeerAssessmentInfo = (code: string) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectPeerAssessmentsIsFetching);
    const peerAssessmentInfo = useSelector(selectPeerAssessmentInfo);
    const fetchError = useSelector(selectPeerAssessmentsError);

    useEffect(() => {
        dispatch(fetchPeerAssessmentInfo(code));
    }, [code, dispatch]);

    return {
        isFetching,
        peerAssessmentInfo,
        fetchError,
    };
};

export default useFetchPeerAssessmentInfo;

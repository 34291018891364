import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchMyAssessments } from '@actions/assessment';
import {
    getMyAssessmentFetchError,
    getMyAssessmentIsFetching,
    getMyAssessments,
    getMyAssessmentsArr,
} from '@selectors/assessment';

const useFetchMyAssessments = () => {
    const dispatch = useDispatch();
    const myAssessments = useSelector(getMyAssessments);
    const myAssessmentsArr = useSelector(getMyAssessmentsArr);
    const isFetching = useSelector(getMyAssessmentIsFetching);
    const fetchError = useSelector(getMyAssessmentFetchError);

    const latestAssessment = myAssessmentsArr.sort(
        (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
    )[0];

    const answerCount = latestAssessment?.answerCount;

    useEffect(() => {
        dispatch(fetchMyAssessments());
    }, [dispatch]);

    return {
        myAssessments,
        latestAssessment,
        answerCount,
        myAssessmentsArr,
        isFetching,
        fetchError,
    };
};

export default useFetchMyAssessments;

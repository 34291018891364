import { RootState } from '@reducers/index';
import { InvitedPeers } from 'src/types/shared/PeerInvitation';

export const getInvitedPeersList = (state: RootState): InvitedPeers[] =>
    state.invitePeersReducer.invitedPeersList;
export const getInvitePeersIsFetching = (state: RootState): boolean =>
    state.invitePeersReducer.isFetching;
export const getInvitePeersFetchError = (state: RootState): string | null =>
    state.invitePeersReducer.fetchError;
export const getIsPosting = (state: RootState): boolean => state.invitePeersReducer.isPosting;
export const getPostError = (state: RootState): string | null => state.invitePeersReducer.postError;
export const getPostInvitePeersSuccess = (state: RootState): boolean =>
    state.invitePeersReducer.postInvitePeerSuccess;

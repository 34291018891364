import { Link, useHistory } from 'react-router-dom';

import DecathlonLogo from 'lib/src/_content/logos/logo-white.svg';

import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import { useState } from 'react';
import { postLogout } from 'lib/src/redux/actions/logout';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import LogoutButton from './LogoutButton';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

const blockBackButtonRoutes = ['/', '/auth/login'];

const Header = (): JSX.Element => {
    const history = useHistory();
    const { location, goBack, length } = history;
    const showBackButton = length > 2 && !blockBackButtonRoutes.includes(location.pathname);
    const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
    const { isLoggedIn } = useIsLoggedIn();

    return (
        <>
            <header className="header">
                <FlexWrapper
                    extraClasses="container area-padding-horizontal"
                    justify="between"
                    align="center"
                >
                    <div className="button-area">
                        {showBackButton && (
                            <button onClick={goBack} aria-label="Go back">
                                <i className="far fa-chevron-left"></i>
                            </button>
                        )}
                    </div>

                    <div className="logo">
                        <Link to="/">
                            <img alt="decathlon logo" src={DecathlonLogo} />
                        </Link>
                    </div>

                    <div className="button-area">
                        {isLoggedIn && <LogoutButton onClick={() => setShowLogoutModal(true)} />}
                    </div>
                </FlexWrapper>
            </header>
            {showLogoutModal && (
                <ConfirmModal
                    title="Logout"
                    description="Are you sure you want to logout?"
                    submitText="Logout"
                    closeModal={() => setShowLogoutModal(false)}
                    handleSubmit={logout}
                />
            )}
        </>
    );

    async function logout() {
        await postLogout();
        setShowLogoutModal(false);
        history.replace('/auth/login');
    }
};

export default Header;

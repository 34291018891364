import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAllTopicsBehaviors } from '@actions/topicsBehaviours/fetchAllTopicsBehaviors';
import {
    getAllTopicsBehaviors,
    geTopicsBehaviorsFetchError,
    geTopicsBehaviorsIsFetching,
} from '@selectors/topicsBehaviors';

const useFetchTopicsBehaviors = () => {
    const dispatch = useDispatch();
    const topicsBehaviors = useSelector(getAllTopicsBehaviors);
    const isFetching = useSelector(geTopicsBehaviorsIsFetching);
    const fetchError = useSelector(geTopicsBehaviorsFetchError);

    useEffect(() => {
        dispatch(fetchAllTopicsBehaviors());
    }, [dispatch]);

    return { topicsBehaviors, isFetching, fetchError };
};

export default useFetchTopicsBehaviors;

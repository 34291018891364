import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Description from 'lib/src/components/typography/Description';
import Title from 'lib/src/components/typography/Title';

const PeerAssessmentExpired = () => {
    return (
        <FlexWrapper extraClasses="full-page area-padding" direction="column" align="center">
            <Title>This Assessment Has Expired.</Title>

            <Description>
                The assessment you are trying to access has expired. Please contact your manager for
                further information.
            </Description>
        </FlexWrapper>
    );
};

export default PeerAssessmentExpired;

import Routes from './routes';

import Header from './components/header/Header';
import useGlobalRedirect from 'lib/src/hooks/useGlobalRedirect';
import useResetScrollOnNav from './hooks/useResetScrollOnNav';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
    useResetScrollOnNav();
    useGlobalRedirect();
    return (
        <div id="web-body" className="body">
            <Header />
            <div id="web-wrapper">
                <div className="container">
                    <Routes />
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default App;

import { RootState } from '@reducers/index';
import { AssessmentResultsSummary } from 'src/types/shared/Assessment';
import { TopicResult } from 'src/types/shared/TopicsBehaviors';

export const selectPeerAssessmentsIsValidating = (state: RootState) =>
    state.peerAssessmentsReducer.isValidating;
export const selectPeerAssessmentsValidationError = (state: RootState) =>
    state.peerAssessmentsReducer.validationError;
export const selectPeerAssessmentsValidationSuccess = (state: RootState) =>
    state.peerAssessmentsReducer.validationSuccess;

export const selectPeerAssessmentsValidationResponse = (state: RootState) =>
    state.peerAssessmentsReducer.validationResponse;

export const selectPeerAssessmentsIsFetching = (state: RootState) =>
    state.peerAssessmentsReducer.isFetching;
export const selectPeerAssessmentsError = (state: RootState) => state.peerAssessmentsReducer.error;

export const selectPeerAssessmentsIsPosting = (state: RootState) =>
    state.peerAssessmentsReducer.isPosting;
export const selectPeerAssessmentsPostError = (state: RootState) =>
    state.peerAssessmentsReducer.postError;

export const selectPeerAssessment = (state: RootState) => state.peerAssessmentsReducer.assessment;

export const selectPeerPostAnswerSuccess = (state: RootState) =>
    state.peerAssessmentsReducer.postAnswerSuccess;

export const selectPeerAssessmentResults = (state: RootState) =>
    state.peerAssessmentsReducer.peerAssessmentResults;

export const getPeerAssessmentResultsSummaryByTopicId = (
    state: RootState,
    topicId: number,
): AssessmentResultsSummary => {
    const peerResults = state.peerAssessmentsReducer.peerAssessmentResults?.leaderResults;
    const peerResultsTopics =
        state.peerAssessmentsReducer.peerAssessmentResults?.leaderResults?.topics || [];
    const leaderTopic = peerResultsTopics.find((topic: TopicResult) => topic.id === topicId);
    const leaderBehaviours = leaderTopic?.behaviours;

    const peerAverageResults =
        state.peerAssessmentsReducer.peerAssessmentResults?.peerAverageResults;
    const peerAverageResultsTopics =
        state.peerAssessmentsReducer.peerAssessmentResults?.peerAverageResults?.topics || [];
    const peerAverageTopic = peerAverageResultsTopics.find(
        (topic: TopicResult) => topic.id === topicId,
    );
    const peerAverageBehaviours = peerAverageTopic?.behaviours;

    return {
        leaderBehaviours,
        peerAverageBehaviours,
        leaderScoreAverage: peerResults?.average || 0,
        peerAverageScoreAverage: peerAverageResults?.average || 0,
        leaderPercentage: peerResults?.percentage || 0,
        peerAveragePercentage: peerAverageResults?.percentage || 0,
    };
};

export const selectPeerAssessmentInfo = (state: RootState) =>
    state.peerAssessmentsReducer.peerAssessmentInfo;

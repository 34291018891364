import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { PeerInvitationPost } from 'src/types/shared/PeerInvitation';

export const postPeerInvitationRequest = createAction('postPeerInvitationRequest');
export const postPeerInvitationSuccess = createAction<PeerInvitationPost>(
    'postPeerInvitationSuccess',
);
export const postPeerInvitationFailure = createAction('postPeerInvitationFailure ');

export const postPeerInvitation =
    (postBody: PeerInvitationPost) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postPeerInvitationRequest());
        try {
            const { data } = await api.post<PeerInvitationPost, PeerInvitationPost>(
                `assessments/invite`,
                postBody,
            );
            dispatch(postPeerInvitationSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postPeerInvitationFailure, e as APIError);
        }
    };

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AssessmentAnswerResponse, AssessmentPostAnswerRequest } from 'src/types/shared/Assessment';

export const postPeerAssessmentAnswerRequest = createAction('postPeerAssessmentAnswerRequest');
export const postPeerAssessmentAnswerSuccess = createAction<AssessmentAnswerResponse>(
    'postPeerAssessmentAnswerSuccess',
);
export const postPeerAssessmentAnswerFailure = createAction('postPeerAssessmentAnswerFailure ');

export const postPeerAssessmentAnswer =
    (postBody: AssessmentPostAnswerRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postPeerAssessmentAnswerRequest());
        try {
            const { data } = await api.post<AssessmentPostAnswerRequest, AssessmentAnswerResponse>(
                `peer/answer`,
                postBody,
            );
            dispatch(postPeerAssessmentAnswerSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postPeerAssessmentAnswerFailure, e as APIError);
        }
    };

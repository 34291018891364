import {
    fetchTopicsBehaviorsRequest,
    fetchTopicsBehaviorsSuccess,
    fetchTopicsBehaviorsFailure,
} from '@actions/topicsBehaviours/fetchAllTopicsBehaviors';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';

interface TopicsBehaviorsState {
    isFetching: boolean;
    fetchError: string | null;
    topicsBehaviors: TopicsBehaviour;
}

const initialState: TopicsBehaviorsState = {
    isFetching: false,
    fetchError: null,
    topicsBehaviors: { topics: [], behaviours: [] },
};

export default createReducer(initialState, {
    [fetchTopicsBehaviorsRequest.type]: handleFetchRequest,
    [fetchTopicsBehaviorsSuccess.type]: handleFetchAllTopicsBehaviorsSuccess,
    [fetchTopicsBehaviorsFailure.type]: handleFetchError,
});

function handleFetchRequest(state: TopicsBehaviorsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchError(state: TopicsBehaviorsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchAllTopicsBehaviorsSuccess(
    state: TopicsBehaviorsState,
    action: PayloadAction<TopicsBehaviour>,
) {
    state.isFetching = false;
    state.topicsBehaviors = action.payload;
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLatestAssessment } from '@actions/assessment';
import useFetchMyAssessments from '@pages/home/hooks/useFetchMyAssessments';
import { getCurrentAssessment, getLatestAssessment } from '@selectors/assessment';
import { isEmpty } from 'lib/src/utils/generic';
import useFetchQuestions from './useFetchQuestions';
import useFetchTopicsBehaviors from './useFetchTopicsBehaviors';
import { useHistory } from 'react-router-dom';

const useAssessmentDataFetch = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentAssessment = useSelector(getCurrentAssessment);
    const latestAssessment = useSelector(getLatestAssessment);
    const assessmentCode = currentAssessment?.assessmentCode ?? latestAssessment?.assessmentCode;
    const { questionsShuffledArr, isFetchingQuestions, fetchQuestionsError } =
        useFetchQuestions(assessmentCode);
    const { topicsBehaviors, isFetching, fetchError } = useFetchTopicsBehaviors();
    const {
        latestAssessment: fetchedLatestAssessment,
        answerCount,
        isFetching: isFetchingAssessments,
        fetchError: assessmentsFetchError,
    } = useFetchMyAssessments();

    const noData = (!currentAssessment && !latestAssessment) || isEmpty(questionsShuffledArr);
    const isLoading = isFetching || isFetchingQuestions || isFetchingAssessments;
    const error = fetchError || fetchQuestionsError || assessmentsFetchError;
    const isInProgress = latestAssessment?.questions?.some(q => q.isAnswered) || false;

    useEffect(() => {
        if (!assessmentCode && fetchedLatestAssessment?.assessmentCode) {
            dispatch(fetchLatestAssessment(fetchedLatestAssessment.assessmentCode));
        }
    }, [dispatch, assessmentCode, fetchedLatestAssessment?.assessmentCode]);

    useEffect(() => {
        if (!isInProgress && latestAssessment?.isComplete)
            history.replace(`/check-results/${assessmentCode}`);
    }, [history, isInProgress, latestAssessment?.isComplete, assessmentCode]);

    return {
        noData,
        isLoading,
        error,
        currentAssessment,
        latestAssessment,
        answerCount,
        questionsShuffledArr,
        topicsBehaviors,
        isInProgress,
    };
};

export default useAssessmentDataFetch;

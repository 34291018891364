import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import Pod from '@components/pods/Pod';
import PageDescription from '@components/typography/PageDescription';
import ActionButton from 'lib/src/components/button/ActionButton';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Meta from 'lib/src/components/meta/Meta';
import Title from 'lib/src/components/typography/Title';
import CompletionBackgroundImage from '../../_content/completion-background.jpeg';
import { postCompletedPeerAssessment } from '@actions/peerAssessment/postCompletedPeerAssessment';
import SecondaryBackground from '@components/secondaryBackground/SecondaryBackground';

const CompletePeerAssessment = () => {
    const dispatch = useDispatch();

    const history = useHistory();
    const { goBack } = history;

    const { code } = useParams<{ code: string }>();

    return (
        <FlexWrapper
            id="assessment-complete"
            direction="column"
            align="center"
            extraClasses="full-page area-padding"
        >
            <Meta title="Submit Your Answers" />
            <SecondaryBackground
                src={CompletionBackgroundImage}
                alt="People raising hands cheerfully on a canoe"
            />

            <FlexWrapper direction="column" align="center" extraClasses="greeting-pod">
                <Pod
                    extraStyle={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginBottom: '30px',
                    }}
                >
                    <Title>Submit Your Answers</Title>

                    <PageDescription>Are you ready to submit your answers?</PageDescription>

                    <Link
                        to={`/peer/check-results/${code}`}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            textDecoration: 'none',
                        }}
                    >
                        <ActionButton
                            onClick={() => {
                                dispatch(postCompletedPeerAssessment(code));
                            }}
                            source="positive"
                        >
                            Submit
                        </ActionButton>
                    </Link>

                    <ActionButton source="secondary" onClick={goBack}>
                        Go back to the previous question
                    </ActionButton>
                    <ActionButton onClick={() => history.push('/assessment?question=1')}>
                        Review answers from beginning
                    </ActionButton>
                </Pod>
            </FlexWrapper>
        </FlexWrapper>
    );
};

export default CompletePeerAssessment;

import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';
import PeerAssessment from '@pages/peer/PeerAssessment';
import PeerResults from '@pages/peerResults/PeerResults';
import CompletePeerAssessment from '@pages/peer/CompletePeerAssessment';
import CheckPeerResults from '@pages/peer/CheckPeerResults';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { postLogout } from 'lib/src/redux/actions/logout';
import PeerAssessmentInfo from '@pages/peerAssessmentInfo/PeerAssessmentInfo';

const PeerRoutes = () => {
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const { isLoggedIn } = useIsLoggedIn();

    useEffect(() => {
        if (isLoggedIn) postLogout();
    }, [dispatch, isLoggedIn]);

    return (
        <Switch>
            <Route exact path={`${path}/:code`}>
                <PeerAssessmentInfo />
            </Route>
            <Route exact path={`${path}/assessment/:code`}>
                <PeerAssessment />
            </Route>
            <Route path={`${path}/check-results/:code`}>
                <CheckPeerResults />
            </Route>
            <Route path={`${path}/results/:code`}>
                <PeerResults />
            </Route>
            <Route path={`${path}/complete-assessment/:code`}>
                <CompletePeerAssessment />
            </Route>
            <DefaultRedirect to="/" />
        </Switch>
    );
};

export default PeerRoutes;

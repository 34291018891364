import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AssessmentQuestion } from 'src/types/shared/Assessment';

export const fetchQuestionsRequest = createAction('fetchQuestionsRequest');
export const fetchQuestionsSuccess = createAction<AssessmentQuestion[]>('fetchQuestionsSuccess');
export const fetchQuestionsFailure = createAction('fetchQuestionsFailure ');

export const fetchQuestions =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchQuestionsRequest());
        try {
            const { data } = await api.get<AssessmentQuestion[]>('assessments/questions');
            dispatch(fetchQuestionsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchQuestionsFailure, e as APIError);
        }
    };

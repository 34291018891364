import {
    fetchQuestionsFailure,
    fetchQuestionsRequest,
    fetchQuestionsShuffledFailure,
    fetchQuestionsShuffledRequest,
    fetchQuestionsShuffledSuccess,
    fetchQuestionsSuccess,
} from '@actions/assessment';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import { AssessmentQuestion } from 'src/types/shared/Assessment';

interface AssessmentQuestionsState {
    isFetching: boolean;
    fetchError: string | null;
    fetchSuccess: boolean;
    assessmentQuestions: Record<number, AssessmentQuestion>;
    assessmentQuestionsShuffled: Record<number, AssessmentQuestion>;
}

const initialState: AssessmentQuestionsState = {
    isFetching: false,
    fetchError: null,
    fetchSuccess: false,
    assessmentQuestions: {},
    assessmentQuestionsShuffled: {},
};

export default createReducer(initialState, {
    [fetchQuestionsRequest.type]: handleFetchRequest,
    [fetchQuestionsSuccess.type]: handleFetchAssessmentQuestionsSuccess,
    [fetchQuestionsFailure.type]: handleFetchError,
    [fetchQuestionsShuffledRequest.type]: handleFetchRequest,
    [fetchQuestionsShuffledSuccess.type]: handleFetchAssessmentQuestionsShuffledSuccess,
    [fetchQuestionsShuffledFailure.type]: handleFetchError,
});

function handleFetchRequest(state: AssessmentQuestionsState) {
    state.isFetching = true;
    state.fetchSuccess = false;
    state.fetchError = null;
}

function handleFetchError(state: AssessmentQuestionsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchAssessmentQuestionsSuccess(
    state: AssessmentQuestionsState,
    action: PayloadAction<AssessmentQuestion[]>,
) {
    state.isFetching = false;
    state.fetchSuccess = true;
    state.assessmentQuestions = convertArrToObj(action.payload);
}

function handleFetchAssessmentQuestionsShuffledSuccess(
    state: AssessmentQuestionsState,
    action: PayloadAction<AssessmentQuestion[]>,
) {
    state.isFetching = false;
    state.fetchSuccess = true;
    state.assessmentQuestionsShuffled = action.payload;
}

import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import PageHeader from '@components/typography/PageHeader';
import {
    CompareAssessmentResult,
    LatestAssessmentResultsResponse,
} from 'src/types/shared/Assessment';

import CompareResultsPod from './CompareResultsPod';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import BubbleHeader from '@components/header/BubbleHeader';
import Select from 'lib/src/components/form/Select';
import { compareLevel } from './constants';
import { onChangeFunction } from 'lib/src/types/shared/FormInputProps';
import { CompareTopicResultWithInfo } from './hooks/useCompareResults';
import { DropdownOption } from 'lib/src/types/shared/DropdownOption';
import AssessmentChart from 'lib/src/components/charts/AssessmentChart';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import useTopicsAndBehavioursForCompareChart from './hooks/useTopicsAndBehavioursForCompareChart';
import useBehaviourDataPointsForCompareChart from './hooks/useBehaviourDataPointsForCompareChart';
import useTopicStatsForCompareChart from './hooks/useTopicStatsForCompareChart';
import useWindowDimensions from 'lib/src/hooks/useWindowDimensions';
import { mediaQueriesMobileFirst } from 'lib/src/constants/mediaQueries';

const CompareResultsInner: React.FC<CompareResultsInnerProps> = ({
    compareResults,
    level,
    handleChange,
    topicResultsWithInfo,
    compareLevelDropdownOptions,
    topicsBehaviors,
    latestAssessmentResults,
}) => {
    const { id } = useIsLoggedIn();
    const windowSize = useWindowDimensions();

    const { topicsForAssessmentChart, behavioursForAssessmentChart } =
        useTopicsAndBehavioursForCompareChart(
            topicsBehaviors,
            latestAssessmentResults.leaderResults,
            latestAssessmentResults.peerAverageResults,
        );

    const behaviourDataPointsForCompareChart = useBehaviourDataPointsForCompareChart(
        topicsBehaviors,
        topicsForAssessmentChart,
        compareResults,
        compareLevelDropdownOptions.find(l => l.value === level)?.label || '',
    );

    const topicStatsForCompareChart = useTopicStatsForCompareChart(
        topicsForAssessmentChart,
        compareResults,
        compareLevelDropdownOptions.find(l => l.value === level)?.label || '',
    );

    return (
        <div id="compare-results" className="full-page">
            <BubbleHeader overlapBottom curveHeight="large">
                <PageHeader>
                    <p style={{ fontSize: '16px', color: 'white' }}>
                        Hello, <strong>{id?.givenName}</strong>
                    </p>
                    {compareResults && (
                        <p style={{ fontSize: '16px', color: 'white' }}>
                            Compare your results below:
                        </p>
                    )}
                </PageHeader>

                <FlexWrapper gap={25} extraClasses="filter-container" justify="center" wrap="wrap">
                    <Select
                        disableRemoveButton
                        name="compareLevel"
                        value={level}
                        onChange={handleChange}
                        options={compareLevelDropdownOptions}
                        extraClasses="level-filter"
                        placeholder="Level"
                    />
                </FlexWrapper>
            </BubbleHeader>

            <FlexWrapper
                direction="column"
                align="center"
                extraClasses="area-padding"
                style={{ paddingTop: 0, marginTop: '150px', zIndex: 0 }}
            >
                {windowSize?.width && windowSize?.width < mediaQueriesMobileFirst.mdDesktop ? (
                    <FlexWrapper
                        direction="column"
                        align="center"
                        justify="between"
                        extraClasses="graph-pods-wrapper"
                    >
                        <FlexWrapper align="center" justify="evenly" wrap="wrap">
                            {topicResultsWithInfo.map((result: CompareTopicResultWithInfo, i) => {
                                return (
                                    <div
                                        key={`assessment-pod-${result.id}`}
                                        style={{ width: `50%` }}
                                        className={`assessment-pod-link ${
                                            i < topicResultsWithInfo.length - 2 ? '' : 'bottom-row'
                                        }`}
                                    >
                                        <CompareResultsPod comparisonTopicResult={result} />
                                    </div>
                                );
                            })}
                        </FlexWrapper>
                    </FlexWrapper>
                ) : (
                    <AssessmentChart
                        topics={topicsForAssessmentChart}
                        behaviours={behavioursForAssessmentChart}
                        dataPoints={behaviourDataPointsForCompareChart}
                        chartStats={topicStatsForCompareChart}
                        isSmallVersion
                        showPercentageOnClick
                    />
                )}
            </FlexWrapper>
        </div>
    );
};

export interface CompareResultsInnerProps {
    topicsBehaviors: TopicsBehaviour;
    latestAssessmentResults: LatestAssessmentResultsResponse;
    compareResults: CompareAssessmentResult;
    level: compareLevel;
    handleChange: onChangeFunction<string | number | null>;
    topicResultsWithInfo: CompareTopicResultWithInfo[];
    compareLevelDropdownOptions: DropdownOption<string>[];
}

export default CompareResultsInner;

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Assessment } from 'src/types/shared/Assessment';

export const postCompletedPeerAssessmentRequest = createAction(
    'postCompletedPeerAssessmentRequest',
);
export const postCompletedPeerAssessmentSuccess = createAction<Assessment>(
    'postCompletedPeerAssessmentSuccess',
);
export const postCompletedPeerAssessmentFailure = createAction(
    'postCompletedPeerAssessmentFailure ',
);

export const postCompletedPeerAssessment =
    (assessmentCode: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postCompletedPeerAssessmentRequest());
        try {
            const { data }: PostCompletedAssessmentResponse = await api.post(`peer/complete`, {
                assessmentCode: assessmentCode,
            });
            dispatch(postCompletedPeerAssessmentSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postCompletedPeerAssessmentFailure, e as APIError);
        }
    };

interface PostCompletedAssessmentResponse {
    data: Assessment;
}

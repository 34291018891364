import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { LatestAssessment } from 'src/types/shared/Assessment';

export const fetchCompareResultsByEntityRequest = createAction(
    'fetchCompareResultsByEntityRequest',
);
export const fetchCompareResultsByEntitySuccess = createAction<LatestAssessment>(
    'fetchCompareResultsByEntitySuccess',
);
export const fetchCompareResultsByEntityFailure = createAction(
    'fetchCompareResultsByEntityFailure',
);

export const fetchCompareResultsByEntity =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchCompareResultsByEntityRequest());
        try {
            const { data } = await api.get<LatestAssessment>(`assessments/compare/entity`);
            dispatch(fetchCompareResultsByEntitySuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchCompareResultsByEntityFailure, e as APIError);
        }
    };

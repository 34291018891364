import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createNewAssessment } from '@actions/assessment';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Pod from '@components/pods/Pod';
import PageHeader from '@components/typography/PageHeader';
import ActionButton from 'lib/src/components/button/ActionButton';
import useAssessmentDataFetch from '@pages/assessment/hooks/useAssessmentDataFetch';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import AssessmentInfoImage from '../../_content/assessment-info.jpg';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';

const AssessmentInfo = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const { isLoading, isInProgress } = useAssessmentDataFetch();
    const { id } = useIsLoggedIn();
    const userName = id?.displayName ?? '';

    const handleCreateNewAssessment = async () => {
        dispatch(createNewAssessment());
        setTimeout(() => history.replace('/assessment'), 500);
    };

    return (
        <FlexWrapper
            id="assessment-info"
            direction="column"
            extraClasses="full-page"
            align="center"
        >
            <Pod>
                <PageHeader centered={false}>
                    <strong>{`Welcome, ${userName}`}</strong>
                </PageHeader>

                <p>
                    Our Leadership Model is an essential part of our transformation. The model is
                    made up of some key stakes and essential skills that will ensure how we play at
                    our best. They will allow us to fulfil and contribute to our new north star, “We
                    move people through the wonders of sport.”
                    <br />
                    <br />
                    This assessment allows you to analyse your current level of leadership as well
                    as identify where you are strong and where you can further develop.
                    <br />
                    <br />
                    At the end of the assessment, you will have the opportunity to nominate between
                    6 and 15 colleagues, including your team and your leader. This will enable you
                    to have a well-rounded view of your leadership against the leadership model.
                    <br />
                    <br />
                    This survey can be taken at any time. On average it takes around 15 minutes to
                    complete but you can save your progress at any moment and come back to it later.
                    <br />
                    <br />
                    Our leadership model is centred around four key stakes as below.
                </p>
            </Pod>

            <FlexWrapper
                direction="row"
                justify="center"
                align="center"
                style={{ margin: '50px 0 50px 0' }}
            >
                <div
                    className="stat-pillar-wrapper"
                    style={{ marginRight: '10px', alignItems: 'end' }}
                >
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifySelf: 'center',
                                marginRight: '10px',
                                textAlign: 'end',
                            }}
                        >
                            <p className="title">Be at our best</p>
                            <p>(focus on self)</p>
                        </div>
                        <div
                            className="stat-pillar"
                            style={{ backgroundColor: 'var(--be-dark)' }}
                        ></div>
                    </div>

                    <div style={{ display: 'flex', marginTop: '40%' }}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifySelf: 'center',
                                textAlign: 'end',
                                marginRight: '10px',
                            }}
                        >
                            <p className="title">Know our Supporters</p>
                            <p className="title">and Playing field</p>
                            <p>(focus on customers and broader</p>
                            <p>ecosystem)</p>
                        </div>
                        <div
                            className="stat-pillar"
                            style={{ backgroundColor: 'var(--know-dark)' }}
                        ></div>
                    </div>
                </div>
                <FlexWrapper
                    direction="column"
                    extraClasses="assessment-info-img-container"
                    style={{
                        backgroundImage: `linear-gradient(transparent,rgba(0,38,59, 0.48)), url(${AssessmentInfoImage})`,
                    }}
                >
                    <div>How</div>
                    <div>we play</div>
                    <div>at</div>
                    <div>our Best</div>
                </FlexWrapper>
                <div className="stat-pillar-wrapper" style={{ marginLeft: '10px' }}>
                    <div style={{ display: 'flex', marginTop: '20%' }}>
                        <div
                            className="stat-pillar"
                            style={{ backgroundColor: 'var(--grow-dark)' }}
                        ></div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifySelf: 'center',
                                marginLeft: '10px',
                            }}
                        >
                            <p className="title">Grow and Inspire</p>

                            <p>(focus on team/others)</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', marginTop: '50%' }}>
                        <div
                            className="stat-pillar"
                            style={{ backgroundColor: 'var(--play-dark)' }}
                        ></div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifySelf: 'center',
                                marginLeft: '10px',
                            }}
                        >
                            <p className="title">Play to Win</p>
                            <p>(Focus on value creation)</p>
                        </div>
                    </div>
                </div>
            </FlexWrapper>
            {!isLoading && (
                <FlexWrapper direction="column" align="center" gap={10}>
                    <h2>Click ‘Begin’ to get started!</h2>
                    <div className="button-wrapper">
                        <ActionButton
                            onClick={() =>
                                isInProgress
                                    ? setShowConfirmModal(true)
                                    : handleCreateNewAssessment()
                            }
                            source="positive"
                            extraClasses="button-container"
                        >
                            Begin
                        </ActionButton>
                    </div>
                </FlexWrapper>
            )}

            {showConfirmModal && (
                <ConfirmModal
                    title="Create New Assessment?"
                    description="Are you sure you want to create a new assessment? All current progress will be lost"
                    closeModal={() => setShowConfirmModal(false)}
                    handleSubmit={handleCreateNewAssessment}
                    submitText="Create"
                />
            )}
        </FlexWrapper>
    );
};

export default AssessmentInfo;

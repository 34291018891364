import useFetchTopicsBehaviors from '@pages/assessment/hooks/useFetchTopicsBehaviors';
import { isEmpty } from 'lib/src/utils/generic';
import useFetchPeerAssessment from './useFetchPeerAssessment';
import useFetchPeerAssessmentQuestions from './useFetchPeerAssessmentQuestions';

const useFetchPeerAssessmentData = (code: string) => {
    const {
        isFetching: isFetchingQuestions,
        fetchError: questionsFetchError,
        peerAssessmentQuestions,
    } = useFetchPeerAssessmentQuestions(code);

    const {
        peerAssessment,
        isFetching: isFetchingAssessment,
        fetchError: fetchAssessmentError,
    } = useFetchPeerAssessment(code);

    const {
        topicsBehaviors,
        isFetching: isFetchingTopicsBehaviors,
        fetchError: topicsBehaviorsError,
    } = useFetchTopicsBehaviors();

    const isFetching = isFetchingQuestions || isFetchingAssessment || isFetchingTopicsBehaviors;
    const fetchError = questionsFetchError || fetchAssessmentError || topicsBehaviorsError;
    const noData = !peerAssessment || isEmpty(peerAssessment) || isEmpty(peerAssessmentQuestions);

    const answerCount = peerAssessment.answerCount;

    return {
        noData,
        isFetching,
        fetchError,
        peerAssessmentQuestions,
        answerCount,
        peerAssessment,
        topicsBehaviors,
    };
};

export default useFetchPeerAssessmentData;

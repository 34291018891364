import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    getInvitedPeersList,
    getInvitePeersFetchError,
    getInvitePeersIsFetching,
} from '@selectors/invitePeers';
import { fetchPeerInvitation } from '@actions/peerInvitation';

const useFetchInvitedPeers = (id: number | undefined) => {
    const dispatch = useDispatch();
    const invitedPeersList = useSelector(getInvitedPeersList);
    const isFetching = useSelector(getInvitePeersIsFetching);
    const fetchError = useSelector(getInvitePeersFetchError);

    useEffect(() => {
        if (typeof id === 'number') dispatch(fetchPeerInvitation(id));
    }, [dispatch, id]);

    const sortedPeers = useMemo(
        () =>
            [...invitedPeersList].sort((a, b) => {
                return +new Date(b.createdOn) - +new Date(a.createdOn);
            }),
        [invitedPeersList],
    );

    return { invitedPeersList: sortedPeers, isFetching, fetchError };
};

export default useFetchInvitedPeers;

import { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import ProgressBar from '@components/progressBar/ProgressBar';
import { AssessmentQuestion, LatestAssessment } from 'src/types/shared/Assessment';
import { TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import useAssessmentInner from './hooks/useAssessmentInner';
import AssessmentPod from './AssessmentPod';
import BubbleHeader from '@components/header/BubbleHeader';
import usePrevious from 'lib/src/hooks/usePrevious';
import ActionButton from 'lib/src/components/button/ActionButton';

const AssessmentInner: React.FC<AssessmentInnerProps> = ({
    questionsArr,
    latestAssessment,
    assessmentCode,
    type,
}) => {
    const history = useHistory();
    const { question, setQuestion, completedScore, pointsArray, form, handleChange } =
        useAssessmentInner({ questionsArr, type, latestAssessment, assessmentCode });
    const [sessionQuestionsAnswered, setSessionQuestionsAnswered] = useState(0);
    const [showBreak, setShowBreak] = useState(false);
    const [breakNumber, setBreakNumber] = useState(0);
    const prevQuestion = usePrevious(question);

    useEffect(() => {
        if (question > prevQuestion) {
            setSessionQuestionsAnswered(state => state + 1);
        }
    }, [question, prevQuestion]);

    useEffect(() => {
        if (sessionQuestionsAnswered && sessionQuestionsAnswered % 7 === 0) {
            setShowBreak(true);
            setBreakNumber(state => (state === 5 ? 1 : state + 1));
        }
    }, [sessionQuestionsAnswered]);

    return (
        <FlexWrapper direction="column" align="center">
            <BubbleHeader extraClasses="assessment-header">
                <ProgressBar completed={completedScore} />
            </BubbleHeader>
            <FlexWrapper id="assessment-page" direction="column" justify="start" align="center">
                <AssessmentPod
                    question={question}
                    questionsArr={questionsArr}
                    form={form}
                    handleChange={handleChange}
                    pointsArray={pointsArray}
                    setQuestion={setQuestion}
                    assessmentCode={assessmentCode}
                    type={type}
                    showBreak={showBreak}
                    breakNumber={breakNumber}
                    setShowBreak={setShowBreak}
                />
            </FlexWrapper>

            <ActionButton
                extraClasses="save-questionnaire-btn"
                onClick={() => history.push('/latest-assessment')}
            >
                Save your progress
            </ActionButton>
        </FlexWrapper>
    );
};

interface AssessmentInnerProps {
    questionsArr: AssessmentQuestion[];
    topicsBehaviors?: TopicsBehaviour;
    latestAssessment?: LatestAssessment | null;
    assessmentCode: string;
    type: 'self' | 'peer';
}

export default AssessmentInner;

import React, { PropsWithChildren } from 'react';

const BubbleHeader: React.FC<BubbleHeaderProps> = ({
    color = '#3643BA',
    children,
    overlapBottom = false,
    curveHeight = 'small',
    extraClasses = '',
}) => {
    const style = color
        ? {
              backgroundColor: color,
          }
        : undefined;

    return (
        <div
            className={`bubble-header ${overlapBottom ? 'overlap' : ''} ${
                curveHeight ? `${curveHeight}-curve` : ''
            } ${extraClasses}`}
        >
            <div style={style} className="content flex-column align-center">
                {children}
            </div>
            <div style={style} className="bottom-curve" />
        </div>
    );
};

interface BubbleHeaderProps extends PropsWithChildren {
    color?: string;
    overlapBottom?: boolean;
    curveHeight?: 'large' | 'medium' | 'small';
    extraClasses?: string;
}

export default BubbleHeader;

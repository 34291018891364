import Meta from 'lib/src/components/meta/Meta';
import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Pod from '@components/pods/Pod';
import PageHeader from '@components/typography/PageHeader';
import PageDescription from '@components/typography/PageDescription';
import PeerInvitationImage from '../../_content/peer-invitation-background.jpeg';
import TextInput from 'lib/src/components/form/TextInput';
import { useParams } from 'react-router-dom';
import useInvitePeer from './hooks/useInvitePeer';
import Form from 'lib/src/components/form/Form';
import ActionButton from 'lib/src/components/button/ActionButton';
import useFetchInvitedPeers from './hooks/useFetchInvitedPeers';
import useFetchMyAssessments from '@pages/home/hooks/useFetchMyAssessments';
import LinkButton from 'lib/src/components/button/LinkButton';
import SecondaryBackground from '@components/secondaryBackground/SecondaryBackground';
import Select from 'lib/src/components/form/Select';
import { InviteeTypesNames } from '@constants/enums';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';

const PeerInvitation: React.FC = (): JSX.Element => {
    const { assessmentCode } = useParams<{ assessmentCode: string }>();
    const { myAssessmentsArr } = useFetchMyAssessments();
    const assessmentID = myAssessmentsArr.find(item => item.assessmentCode === assessmentCode)?.id;
    const { invitedPeersList, isFetching } = useFetchInvitedPeers(assessmentID);

    const {
        form,
        handleChange,
        handleSubmit,
        isPosting,
        invitees,
        addInvitee,
        removeInvitee,
        inviteeTypeOptions,
        inviteesError,
    } = useInvitePeer(assessmentCode);

    return (
        <FlexWrapper
            id="peers-invitation"
            direction="column"
            align="center"
            extraClasses="full-page area-padding"
        >
            <Meta title="Peer Invitation" />
            <SecondaryBackground src={PeerInvitationImage} alt="Happy mountaineers" />

            <FlexWrapper direction="column" align="center" extraClasses="peer-invites-pod">
                <Pod extraStyle={{ marginBottom: '30px' }}>
                    <PageHeader>
                        <strong>Invite Feedback</strong>
                    </PageHeader>

                    <PageDescription>
                        Please type the email of the person you want to nominate and select their
                        relationship with you.
                    </PageDescription>

                    <PageDescription>
                        You must nominate a minimum of 3 people but it is necessary to ask ALL of
                        your team and your leader.
                    </PageDescription>

                    <PageDescription>
                        Feedback is anonymous except for that of your leader.
                    </PageDescription>

                    <DataCheck isEmpty={isFetching} isLoading={isFetching} error={null}>
                        <Form
                            isPosting={isPosting}
                            onSubmit={handleSubmit}
                            error={inviteesError}
                            omitButtons
                        >
                            <FlexWrapper gap={15} extraClasses="add-peer-invite-wrapper">
                                <div className="flex-8">
                                    <TextInput
                                        name="email"
                                        value={form.email}
                                        type="email"
                                        onChange={handleChange}
                                        placeholder="Enter an email address"
                                    />
                                </div>

                                <div className="flex-4">
                                    <Select
                                        name="type"
                                        value={form.type}
                                        onChange={handleChange}
                                        options={inviteeTypeOptions}
                                        disableRemoveButton
                                    />
                                </div>

                                <ActionButton
                                    onClick={() => addInvitee()}
                                    icon="plus"
                                    type="button"
                                    source="positive"
                                >
                                    Add
                                </ActionButton>
                            </FlexWrapper>

                            {!!invitees.length && (
                                <FlexWrapper direction="column" extraClasses="invited-peers-list">
                                    <h2 className="header-text">
                                        <strong>Peers to invite:</strong>
                                    </h2>
                                    <ul>
                                        {invitees.map(peer => (
                                            <li key={peer.email}>
                                                <FlexWrapper justify="between" align="center">
                                                    <p>
                                                        {peer.email} ({InviteeTypesNames[peer.type]}
                                                        )
                                                    </p>
                                                    <ActionButton
                                                        icon="times"
                                                        onClick={() => removeInvitee(peer.email)}
                                                    >
                                                        Remove
                                                    </ActionButton>
                                                </FlexWrapper>
                                            </li>
                                        ))}
                                    </ul>
                                </FlexWrapper>
                            )}

                            {!!invitedPeersList.length && (
                                <FlexWrapper direction="column" extraClasses="invited-peers-list">
                                    <h2 className="header-text">
                                        <strong>Already invited:</strong>
                                    </h2>
                                    <ul>
                                        {invitedPeersList.map(peer => (
                                            <li key={peer.email}>
                                                <FlexWrapper justify="between" align="center">
                                                    <p>
                                                        {peer.email} (
                                                        {InviteeTypesNames[peer.inviteeType]})
                                                    </p>
                                                </FlexWrapper>
                                            </li>
                                        ))}
                                    </ul>
                                </FlexWrapper>
                            )}

                            <FlexWrapper
                                direction="column"
                                align="center"
                                extraClasses="invite-peer-form-buttons"
                            >
                                <ActionButton
                                    type="submit"
                                    disabled={!invitees.length}
                                    isPosting={isPosting}
                                >
                                    Invite Peers
                                </ActionButton>
                                <LinkButton
                                    extraClasses="invite-button"
                                    href="/latest-assessment"
                                    source="secondary"
                                >
                                    Go to results
                                </LinkButton>
                            </FlexWrapper>
                        </Form>
                    </DataCheck>
                </Pod>
            </FlexWrapper>
        </FlexWrapper>
    );
};

export default PeerInvitation;

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { LatestAssessment } from '../../../types/shared/Assessment';

export const fetchPeerAssessmentResultsRequest = createAction('fetchPeerAssessmentResultsRequest');
export const fetchPeerAssessmentResultsSuccess = createAction<LatestAssessment>(
    'fetchPeerAssessmentResultsSuccess',
);
export const fetchPeerAssessmentResultsFailure = createAction('fetchPeerAssessmentResultsFailure');

export const fetchPeerAssessmentResults =
    (code: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchPeerAssessmentResultsRequest());
        try {
            const { data } = await api.get<LatestAssessment>(`peer/results/${code}`);
            dispatch(fetchPeerAssessmentResultsSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchPeerAssessmentResultsFailure, e as APIError);
        }
    };

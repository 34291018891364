import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPeerAssessmentResults } from '@actions/peerAssessment/fetchPeerAssessmentResults';
import {
    selectPeerAssessmentResults,
    selectPeerAssessmentsIsFetching,
    selectPeerAssessmentsError,
} from '@selectors/peerAssessments';

const useFetchPeerAssessmentResults = (code: string) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(selectPeerAssessmentsIsFetching);
    const fetchError = useSelector(selectPeerAssessmentsError);
    const peerAssessmentResults = useSelector(selectPeerAssessmentResults);

    useEffect(() => {
        dispatch(fetchPeerAssessmentResults(code));
    }, [dispatch, code]);

    return { isFetching, fetchError, peerAssessmentResults };
};

export default useFetchPeerAssessmentResults;

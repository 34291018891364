import { fetchPeerAssessmentQuestions } from '@actions/peerAssessment/fetchPeerAssessmentQuestions';
import {
    getPeerAssessmentQuestionsArr,
    getPeerAssessmentQuestionsFetchError,
    getPeerAssessmentQuestionsIsFetching,
} from '@selectors/peerAssessmentQuestions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const useFetchPeerAssessmentQuestions = (code: string) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getPeerAssessmentQuestionsIsFetching);
    const fetchError = useSelector(getPeerAssessmentQuestionsFetchError);
    const peerAssessmentQuestions = useSelector(getPeerAssessmentQuestionsArr);

    useEffect(() => {
        dispatch(fetchPeerAssessmentQuestions(code));
    }, [dispatch, code]);

    return {
        isFetching,
        fetchError,
        peerAssessmentQuestions,
    };
};

export default useFetchPeerAssessmentQuestions;

import React from 'react';
import {
    ComposedChart as Chart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Brush,
    Cell,
    LabelList,
} from 'recharts';

const AssessmentDetailsChart: React.FC<AssessmentDetailsChartProps> = ({
    data = [],
    bars = [],
    referenceLines = [],
    layout = 'horizontal',
    showTooltip = false,
    XAxisTicks = true,
    YAxisTicks = true,
    ninetyFiveReferenceLabel = false,
    scroll = false,
    radius = [0, 0, 0, 0],
    containerClasses = '',
    minHeight = '100px',
    colour,
    colourDark,
    hideXAxis = false,
    hideYAxis = false,
}) => {
    const isHorizontal = layout === 'horizontal';

    return (
        <ResponsiveContainer minHeight={minHeight} className={containerClasses}>
            <Chart data={data} layout={layout} width={100}>
                <XAxis
                    type={'number'}
                    dataKey={'score'}
                    tick={XAxisTicks}
                    unit="%"
                    domain={[0, 100]}
                    hide={hideXAxis}
                />
                <YAxis
                    type="category"
                    dataKey={'name'}
                    tickLine={false}
                    interval={0}
                    tick={YAxisTicks}
                    hide={hideYAxis}
                />
                {scroll && data.length > 2 && <Brush dataKey="name" height={30} stroke="#8884d8" />}
                {showTooltip && <Tooltip cursor={{ opacity: 0.1, fill: '#004876' }} />}
                <Legend
                    payload={referenceLines.map((line: ReferenceLineProps) => ({
                        value: line.label,
                        type: 'plainline',
                        color: line.stroke,
                        payload: { strokeDasharray: '' }, // Needed to overcome library bug when using plainline type
                    }))}
                />
                {referenceLines.map((line: ReferenceLineProps, i: number) => (
                    <ReferenceLine
                        key={`${line.value}-${i}`}
                        x={isHorizontal ? undefined : line.value}
                        y={isHorizontal ? line.value : undefined}
                        stroke={line.stroke}
                        strokeWidth={2}
                        label={
                            ninetyFiveReferenceLabel
                                ? {
                                      position: 'left',
                                      value: '95%',
                                      fill: 'green',
                                  }
                                : false
                        }
                    />
                ))}
                {bars.map((bar: BarProps, i: number) => {
                    return (
                        <Bar
                            key={`${bar.dataKey}=${i}`}
                            dataKey={bar.dataKey}
                            fill={bar.fill}
                            radius={radius}
                            unit="%"
                        >
                            {data.map((_, i) => (
                                <Cell
                                    cursor="pointer"
                                    fill={i === 0 ? colourDark : colour}
                                    key={`cell-${i}`}
                                />
                            ))}
                        </Bar>
                    );
                })}
            </Chart>
        </ResponsiveContainer>
    );
};

interface AssessmentDetailsChartProps {
    data: any[];
    bars: BarProps[];
    referenceLines: ReferenceLineProps[];
    layout?: 'horizontal' | 'vertical';
    showTooltip?: boolean;
    XAxisTicks?: boolean;
    YAxisTicks?: boolean;
    ninetyFiveReferenceLabel?: boolean;
    scroll?: boolean;
    radius?: [number, number, number, number];
    containerClasses?: string;
    minHeight?: string | number;
    colour: string;
    colourDark: string;
    hideXAxis?: boolean;
    hideYAxis?: boolean;
}

export interface BarProps {
    dataKey: string;
    fill: string;
}

export interface LineProps {
    dataKey: string;
    stroke: string;
}

export interface ReferenceLineProps {
    label: string;
    value: number;
    stroke: string;
}

export default AssessmentDetailsChart;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validatePeerAssessment } from '@actions/peerAssessment/validatePeerAsssessment';
import {
    selectPeerAssessmentsIsValidating,
    selectPeerAssessmentsValidationResponse,
} from '@selectors/peerAssessments';

const useValidatePeerAssessment = (code?: string) => {
    const dispatch = useDispatch();

    const assessmentResponse = useSelector(selectPeerAssessmentsValidationResponse);
    const isValidating = useSelector(selectPeerAssessmentsIsValidating);

    useEffect(() => {
        if (code) {
            dispatch(validatePeerAssessment(code));
        }
    }, [code, dispatch]);

    return {
        assessmentResponse,
        isValidating,
    };
};

export default useValidatePeerAssessment;

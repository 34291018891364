import CompareResultsInner from './CompareResultsInner';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import { isEmpty } from 'lib/src/utils/generic';
import Meta from 'lib/src/components/meta/Meta';
import useCompareResults from './hooks/useCompareResults';

const CompareResults = () => {
    const {
        level,
        topicsBehaviors,
        latestAssessmentResults,
        compareResults,
        topicResultsWithInfo,
        isFetching,
        fetchError,
        handleChange,
        compareLevelDropdownOptions,
    } = useCompareResults();

    return (
        <>
            <Meta title="Compare Results" />
            <DataCheck
                isEmpty={
                    isEmpty(topicResultsWithInfo) ||
                    isEmpty(compareResults) ||
                    isEmpty(latestAssessmentResults)
                }
                isLoading={isFetching}
                error={fetchError}
            >
                {latestAssessmentResults && (
                    <CompareResultsInner
                        topicsBehaviors={topicsBehaviors}
                        latestAssessmentResults={latestAssessmentResults}
                        compareResults={compareResults}
                        level={level}
                        handleChange={handleChange}
                        topicResultsWithInfo={topicResultsWithInfo}
                        compareLevelDropdownOptions={compareLevelDropdownOptions}
                    />
                )}
            </DataCheck>
        </>
    );
};

export default CompareResults;

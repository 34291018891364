import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchQuestions, fetchQuestionsShuffled } from '@actions/assessment';
import {
    getAssessmentQuestions,
    getAssessmentQuestionsArr,
    getAssessmentQuestionsFetchError,
    getAssessmentQuestionsIsFetching,
    getAssessmentQuestionsShuffledArr,
} from '@selectors/assessmentQuestions';

const useFetchQuestions = (assessmentCode: string | undefined) => {
    const dispatch = useDispatch();
    const questions = useSelector(getAssessmentQuestions);
    const questionsArr = useSelector(getAssessmentQuestionsArr);
    const questionsShuffledArr = useSelector(getAssessmentQuestionsShuffledArr);
    const isFetchingQuestions = useSelector(getAssessmentQuestionsIsFetching);
    const fetchQuestionsError = useSelector(getAssessmentQuestionsFetchError);

    useEffect(() => {
        dispatch(fetchQuestions());
    }, [dispatch]);

    useEffect(() => {
        if (assessmentCode) {
            dispatch(fetchQuestionsShuffled(assessmentCode));
        }
    }, [assessmentCode, dispatch]);

    return {
        questions,
        questionsArr,
        questionsShuffledArr,
        isFetchingQuestions,
        fetchQuestionsError,
    };
};

export default useFetchQuestions;

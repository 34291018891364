import dev from './dev.config';
import staging from './staging.config';
import production from './production.config';

const env: Environment = (process.env.REACT_APP_ENVIRONMENT as Environment) || 'dev';

const configs = {
    dev,
    staging,
    production,
};
const config = configs[env];
export const { API_URL, OAUTH_ACCESS_TOKEN_URL, OAUTH_URL, OAUTH_CLIENT_ID, OAUTH_REDIRECT_URL } =
    config;

export default configs[env];

export interface Config {
    API_URL: string;
    OAUTH_URL: string;
    OAUTH_CLIENT_ID: string;
    OAUTH_REDIRECT_URL: string;
    OAUTH_ACCESS_TOKEN_URL: string;
}

type Environment = 'dev' | 'staging' | 'production';

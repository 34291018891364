import CompareResults from '@pages/compareResults/CompareResults';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import DefaultRedirect from './DefaultRedirect';

const CompareRoutes = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <CompareResults />
            </Route>
            <DefaultRedirect to="/" />
        </Switch>
    );
};

export default CompareRoutes;

import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import PageHeader from '@components/typography/PageHeader';
import Pod from '@components/pods/Pod';
import { LatestAssessmentResultsResponse } from 'src/types/shared/Assessment';
import { Topic, TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';

import useWindowDimensions from 'lib/src/hooks/useWindowDimensions';
import { mediaQueriesMobileFirst } from 'lib/src/constants/mediaQueries';
import PeerAssessmentPod from './PeerAssessmentPod';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import BubbleHeader from '@components/header/BubbleHeader';
import LatestAssessmentResults from '@pages/latestAssessment/LatestAssessmentResults';

const PeerResultsInner: React.FC<PeerResultsInnerProps> = ({
    topicsBehaviours,
    peerAssessmentResults,
}) => {
    const { id } = useIsLoggedIn();
    const windowSize = useWindowDimensions();
    const topics = topicsBehaviours?.topics || [];

    return (
        <div className="full-page">
            <BubbleHeader overlapBottom curveHeight="large">
                <PageHeader>
                    <p style={{ fontSize: '16px', color: 'white' }}>
                        Hello, <strong>{id?.givenName}</strong>
                    </p>
                    {peerAssessmentResults && (
                        <p style={{ fontSize: '16px', color: 'white' }}>
                            Below are your submitted answers
                        </p>
                    )}
                </PageHeader>
            </BubbleHeader>
            <FlexWrapper
                direction="column"
                justify="between"
                extraClasses="area-padding"
                style={{ paddingTop: 0, marginTop: '-20px' }}
            >
                {windowSize?.width && windowSize?.width < mediaQueriesMobileFirst.mdDesktop ? (
                    <FlexWrapper
                        direction="column"
                        align="center"
                        justify="between"
                        extraClasses="graph-pods-wrapper"
                    >
                        <FlexWrapper align="center" justify="evenly" wrap="wrap">
                            {topics.map((topic: Topic, i) => {
                                return (
                                    <div
                                        key={`assessment-pod-${topic.id}`}
                                        style={{ width: `50%` }}
                                        className={`assessment-pod-link ${
                                            i < topics.length - 2 ? '' : 'bottom-row'
                                        }`}
                                    >
                                        <PeerAssessmentPod
                                            title={topic.name}
                                            colour={topic.colour}
                                            colourDark={topic.colourDark}
                                            topicId={topic.id}
                                        />
                                    </div>
                                );
                            })}
                        </FlexWrapper>
                    </FlexWrapper>
                ) : (
                    <>
                        <Pod
                            extraStyle={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '20px',
                            }}
                        >
                            {peerAssessmentResults && (
                                <LatestAssessmentResults
                                    latestAssessmentResults={peerAssessmentResults}
                                    topicsBehaviours={topicsBehaviours}
                                />
                            )}
                        </Pod>
                    </>
                )}
            </FlexWrapper>
        </div>
    );
};

interface PeerResultsInnerProps {
    topicsBehaviours: TopicsBehaviour;
    peerAssessmentResults: LatestAssessmentResultsResponse;
}

export default PeerResultsInner;

import { useParams } from 'react-router-dom';
import useValidatePeerAssessment from '@pages/peer/hooks/useValidatePeerAssessment';

import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';

import PeerAssessmentExpired from './PeerAssessmentExpired';
import PeerAssessmentActive from './PeerAssessmentActive';
import Meta from 'lib/src/components/meta/Meta';

const PeerAssessment = () => {
    const { code } = useParams<{ code: string }>();
    const { assessmentResponse, isValidating } = useValidatePeerAssessment(code);

    return (
        <>
            <Meta title="Peer Assessment" />
            <DataCheck isEmpty={!assessmentResponse} isLoading={isValidating}>
                {!assessmentResponse?.isExpired ? (
                    <PeerAssessmentActive code={code} />
                ) : (
                    <PeerAssessmentExpired />
                )}
            </DataCheck>
        </>
    );
};
export default PeerAssessment;

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AssessmentAnswerResponse, AssessmentPostAnswerRequest } from 'src/types/shared/Assessment';

export const postAssessmentAnswerRequest = createAction('postAssessmentAnswerRequest');
export const postAssessmentAnswerSuccess = createAction<AssessmentAnswerResponse>(
    'postAssessmentAnswerSuccess',
);
export const postAssessmentAnswerFailure = createAction('postAssessmentAnswerFailure ');

export const postAssessmentAnswer =
    (postBody: AssessmentPostAnswerRequest) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(postAssessmentAnswerRequest());
        try {
            const { data } = await api.post<AssessmentPostAnswerRequest, AssessmentAnswerResponse>(
                `assessments/answer`,
                postBody,
            );
            dispatch(postAssessmentAnswerSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, postAssessmentAnswerFailure, e as APIError);
        }
    };

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { AssessmentValidationResponse } from 'src/types/shared/Assessment';

export const validatePeerAssessmentRequest = createAction('validatePeerAssessmentRequest');
export const validatePeerAssessmentSuccess = createAction<AssessmentValidationResponse>(
    'validatePeerAssessmentSuccess',
);
export const validatePeerAssessmentFailure = createAction('validatePeerAssessmentFailure');

export const validatePeerAssessment =
    (code: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(validatePeerAssessmentRequest());
        try {
            const { data } = await api.get<AssessmentValidationResponse>(`peer/validate/${code}`);
            dispatch(validatePeerAssessmentSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, validatePeerAssessmentFailure, e as APIError);
        }
    };

import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    createNewAssessmentFailure,
    createNewAssessmentRequest,
    createNewAssessmentSuccess,
} from '@actions/assessment/createNewAssessment';
import {
    Assessment,
    AssessmentAnswerResponse,
    CompareAssessmentResult,
    LatestAssessment,
    LatestAssessmentResultsResponse,
} from 'src/types/shared/Assessment';
import {
    fetchLatestAssessmentFailure,
    fetchLatestAssessmentRequest,
    fetchLatestAssessmentSuccess,
    fetchLatestAssessmentResultsFailure,
    fetchLatestAssessmentResultsRequest,
    fetchLatestAssessmentResultsSuccess,
    fetchMyAssessmentsFailure,
    fetchMyAssessmentsRequest,
    fetchMyAssessmentsSuccess,
    postAssessmentAnswerFailure,
    postAssessmentAnswerRequest,
    postAssessmentAnswerSuccess,
    postCompletedAssessmentFailure,
    postCompletedAssessmentRequest,
    postCompletedAssessmentSuccess,
    fetchCompareResultsByTypeRequest,
    fetchCompareResultsByTypeSuccess,
    fetchCompareResultsByTypeFailure,
    fetchCompareResultsByTypeAboveRequest,
    fetchCompareResultsByTypeAboveSuccess,
    fetchCompareResultsByTypeAboveFailure,
    fetchCompareResultsByEntityRequest,
    fetchCompareResultsByEntitySuccess,
    fetchCompareResultsByEntityFailure,
} from '@actions/assessment';
import { convertArrToObj } from 'lib/src/utils/generic';

interface AssessmentState {
    isFetching: boolean;
    isFetchingLatestAssessmentResults: boolean;
    isPosting: boolean;
    fetchError: string | null;
    latestAssessmentResultsFetchError: string | null;
    postError: string | null;
    fetchMyAssessmentsSuccess: boolean;
    fetchLatestAssessmentSuccess: boolean;
    fetchLatestAssessmentResultsSuccess: boolean;
    postCreateAssessmentSuccess: boolean;
    postCompleteAssessmentSuccess: boolean;
    postAnswerSuccess: boolean;
    currentAssessment: Assessment | null;
    assessmentAnswer: AssessmentAnswerResponse | null;
    latestAssessment: LatestAssessment | null;
    latestAssessmentResults: LatestAssessmentResultsResponse | null;
    assessments: Record<number, Assessment>;
    compareResults: CompareAssessmentResult;
    isFetchingCompareResults: boolean;
    fetchCompareResultsError: string | null;
}

const initialState: AssessmentState = {
    isFetching: false,
    isFetchingLatestAssessmentResults: false,
    isPosting: false,
    fetchError: null,
    latestAssessmentResultsFetchError: null,
    postError: null,
    fetchMyAssessmentsSuccess: false,
    fetchLatestAssessmentSuccess: false,
    fetchLatestAssessmentResultsSuccess: false,
    postCreateAssessmentSuccess: false,
    postCompleteAssessmentSuccess: false,
    postAnswerSuccess: false,
    currentAssessment: null,
    latestAssessment: null,
    latestAssessmentResults: null,
    assessmentAnswer: null,
    assessments: {},
    compareResults: {} as CompareAssessmentResult,
    isFetchingCompareResults: false,
    fetchCompareResultsError: null,
};

export default createReducer(initialState, {
    [createNewAssessmentRequest.type]: handleCreateAssessmentRequest,
    [createNewAssessmentSuccess.type]: handleCreateNewAssessmentSuccess,
    [createNewAssessmentFailure.type]: handlePostError,
    [postAssessmentAnswerRequest.type]: handlePostAnswerRequest,
    [postAssessmentAnswerSuccess.type]: handlePostAssessmentAnswerSuccess,
    [postAssessmentAnswerFailure.type]: handlePostError,
    [postCompletedAssessmentRequest.type]: handlePostCompletedAssessmentRequest,
    [postCompletedAssessmentSuccess.type]: handlePostCompletedAssessmentSuccess,
    [postCompletedAssessmentFailure.type]: handlePostError,
    [fetchMyAssessmentsRequest.type]: handleFetchMyAssessmentsRequest,
    [fetchMyAssessmentsSuccess.type]: handleFetchMyAssessmentsSuccess,
    [fetchMyAssessmentsFailure.type]: handleFetchError,
    [fetchLatestAssessmentRequest.type]: handleFetchLatestAssessmentRequest,
    [fetchLatestAssessmentSuccess.type]: handleFetchLatestAssessmentSuccess,
    [fetchLatestAssessmentFailure.type]: handleFetchError,
    [fetchLatestAssessmentResultsRequest.type]: handleFetchLatestAssessmentResultsRequest,
    [fetchLatestAssessmentResultsSuccess.type]: handleFetchLatestAssessmentResultsSuccess,
    [fetchLatestAssessmentResultsFailure.type]: handleFetchLatestAssessmentResultsFailure,
    [fetchCompareResultsByTypeRequest.type]: handleFetchCompareResultsRequest,
    [fetchCompareResultsByTypeSuccess.type]: handleFetcCompareResultsSuccess,
    [fetchCompareResultsByTypeFailure.type]: handleFetchCompareResultsFailure,
    [fetchCompareResultsByTypeAboveRequest.type]: handleFetchCompareResultsRequest,
    [fetchCompareResultsByTypeAboveSuccess.type]: handleFetcCompareResultsSuccess,
    [fetchCompareResultsByTypeAboveFailure.type]: handleFetchCompareResultsFailure,
    [fetchCompareResultsByEntityRequest.type]: handleFetchCompareResultsRequest,
    [fetchCompareResultsByEntitySuccess.type]: handleFetcCompareResultsSuccess,
    [fetchCompareResultsByEntityFailure.type]: handleFetchCompareResultsFailure,
});

function handleFetchMyAssessmentsRequest(state: AssessmentState) {
    state.isFetching = true;
    state.fetchError = null;
    state.fetchMyAssessmentsSuccess = false;
}

function handleFetchLatestAssessmentRequest(state: AssessmentState) {
    state.isFetching = true;
    state.fetchError = null;
    state.fetchLatestAssessmentSuccess = false;
}

function handleFetchLatestAssessmentResultsRequest(state: AssessmentState) {
    state.isFetchingLatestAssessmentResults = true;
    state.latestAssessmentResultsFetchError = null;
    state.fetchLatestAssessmentResultsSuccess = false;
}

function handleFetchCompareResultsRequest(state: AssessmentState) {
    state.isFetchingCompareResults = true;
    state.fetchCompareResultsError = null;
}

function handleFetchError(state: AssessmentState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchLatestAssessmentResultsFailure(
    state: AssessmentState,
    action: PayloadAction<string>,
) {
    state.isFetchingLatestAssessmentResults = false;
    state.latestAssessmentResultsFetchError = action.payload;
}

function handleFetchCompareResultsFailure(state: AssessmentState, action: PayloadAction<string>) {
    state.isFetchingCompareResults = false;
    state.fetchCompareResultsError = action.payload;
}

function handleFetchMyAssessmentsSuccess(
    state: AssessmentState,
    action: PayloadAction<Assessment[]>,
) {
    state.isFetching = false;
    state.fetchMyAssessmentsSuccess = true;
    state.assessments = convertArrToObj(action.payload);
}

function handleFetchLatestAssessmentSuccess(
    state: AssessmentState,
    action: PayloadAction<LatestAssessment>,
) {
    state.fetchLatestAssessmentSuccess = true;
    state.isFetching = false;
    state.latestAssessment = action.payload;
}

function handleFetchLatestAssessmentResultsSuccess(
    state: AssessmentState,
    action: PayloadAction<LatestAssessmentResultsResponse>,
) {
    state.fetchLatestAssessmentResultsSuccess = true;
    state.isFetchingLatestAssessmentResults = false;
    state.latestAssessmentResults = action.payload;
}

function handleFetcCompareResultsSuccess(
    state: AssessmentState,
    action: PayloadAction<CompareAssessmentResult>,
) {
    state.isFetchingCompareResults = false;
    state.compareResults = action.payload;
}

function handleCreateAssessmentRequest(state: AssessmentState) {
    state.isPosting = true;
    state.postError = null;
    state.postCreateAssessmentSuccess = false;
}

function handlePostAnswerRequest(state: AssessmentState) {
    state.isPosting = true;
    state.postError = null;
    state.postAnswerSuccess = false;
}

function handlePostCompletedAssessmentRequest(state: AssessmentState) {
    state.isPosting = true;
    state.postError = null;
    state.postCompleteAssessmentSuccess = false;
}

function handlePostError(state: AssessmentState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleCreateNewAssessmentSuccess(
    state: AssessmentState,
    action: PayloadAction<Assessment>,
) {
    state.isPosting = false;
    state.postCreateAssessmentSuccess = true;
    state.currentAssessment = action.payload;
    state.latestAssessment = null;
}

function handlePostAssessmentAnswerSuccess(
    state: AssessmentState,
    action: PayloadAction<AssessmentAnswerResponse>,
) {
    state.isPosting = false;
    state.postAnswerSuccess = true;
    state.assessmentAnswer = action.payload;
    if (state.latestAssessment) {
        const questionToUpdate = state.latestAssessment.questions.find(
            question => question.id === action.payload.questionID,
        );
        if (questionToUpdate) {
            questionToUpdate.answer = action.payload.answer
                ? +action.payload.answer
                : action.payload.answer;
        }
    }
}

function handlePostCompletedAssessmentSuccess(
    state: AssessmentState,
    action: PayloadAction<Assessment>,
) {
    state.isPosting = false;
    state.postCompleteAssessmentSuccess = true;
}

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { LatestAssessment } from 'src/types/shared/Assessment';

export const fetchCompareResultsByTypeRequest = createAction('fetchCompareResultsByTypeRequest');
export const fetchCompareResultsByTypeSuccess = createAction<LatestAssessment>(
    'fetchCompareResultsByTypeSuccess',
);
export const fetchCompareResultsByTypeFailure = createAction('fetchCompareResultsByTypeFailure');

export const fetchCompareResultsByType =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchCompareResultsByTypeRequest());
        try {
            const { data } = await api.get<LatestAssessment>(`assessments/compare/type`);
            dispatch(fetchCompareResultsByTypeSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchCompareResultsByTypeFailure, e as APIError);
        }
    };

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useForm from 'lib/src/hooks/useForm';
import { getIsPosting, getPostInvitePeersSuccess } from '@selectors/invitePeers';
import { postPeerInvitation } from '@actions/peerInvitation';
import { InviteeTypes } from '@constants/enums';
import { DropdownOption } from 'lib/src/types/shared/DropdownOption';
import { getFieldError } from 'lib/src/redux/selectors/fieldErrors';
import { Invitee } from 'src/types/shared/PeerInvitation';
import { removeFieldError } from 'lib/src/redux/actions/fieldErrors';
import usePrevious from 'lib/src/hooks/usePrevious';
import { toast } from 'react-toastify';

const useInvitePeer = (assessmentCode: string) => {
    const dispatch = useDispatch();
    const isPosting = useSelector(getIsPosting);
    const inviteSuccess = useSelector(getPostInvitePeersSuccess);
    const prevInviteSuccess = usePrevious(inviteSuccess);
    const [invitees, setInvitees] = useState<Invitee[]>([]);

    const [form, handleChange, resetForm] = useForm(initialFormData);

    const emailError = useSelector(state => getFieldError(state, 'email'));
    const inviteesError = useSelector(state => getFieldError(state, 'invitees'));

    useEffect(() => {
        if (inviteSuccess && !prevInviteSuccess) {
            setInvitees([]);
            toast.success('Invite(s) successfully sent!');
        }
    }, [inviteSuccess, prevInviteSuccess]);

    const addInvitee = () => {
        if (
            emailError ||
            !form.email ||
            invitees.map(invitee => invitee.email).includes(form.email)
        ) {
            return;
        }

        dispatch(removeFieldError('invitees'));
        setInvitees(state => [...state, form]);
        resetForm(initialFormData);
    };

    const removeInvitee = (email: string) => {
        dispatch(removeFieldError('invitees'));
        setInvitees(state => state.filter(invitee => invitee.email !== email));
    };

    const handleSubmit = () => {
        dispatch(postPeerInvitation({ assessmentCode: assessmentCode, invitees: invitees }));
    };

    return {
        form,
        handleChange,
        handleSubmit,
        isPosting,
        invitees,
        addInvitee,
        removeInvitee,
        inviteeTypeOptions,
        inviteesError,
        inviteSuccess,
    };
};

export const initialFormData: Invitee = {
    email: '',
    type: InviteeTypes.Leader,
};

export const inviteeTypeOptions: DropdownOption<number | string | null>[] = [
    {
        label: 'My Leader',
        value: InviteeTypes.Leader,
    },
    {
        label: 'My Team',
        value: InviteeTypes.Team,
    },
    {
        label: 'Other',
        value: InviteeTypes.Other,
    },
];

export default useInvitePeer;

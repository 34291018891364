import { RootState } from '@reducers/index';
import {
    Assessment,
    AssessmentAnswerResponse,
    AssessmentResult,
    AssessmentResultsSummary,
    CompareAssessmentResult,
    LatestAssessment,
    LatestAssessmentResultsResponse,
} from 'src/types/shared/Assessment';
import { QuestionResult } from 'src/types/shared/Question';
import { BehaviourResult, TopicResult } from 'src/types/shared/TopicsBehaviors';

export const getMyAssessments = (state: RootState): Record<number, Assessment> =>
    state.assessmentReducer.assessments;
export const getMyAssessmentsArr = (state: RootState): Assessment[] =>
    Object.values(state.assessmentReducer.assessments);
export const getMyAssessmentIsFetching = (state: RootState): boolean =>
    state.assessmentReducer.isFetching;
export const getLatestAssessmentResultsIsFetching = (state: RootState): boolean =>
    state.assessmentReducer.isFetchingLatestAssessmentResults;
export const getMyAssessmentFetchError = (state: RootState): string | null =>
    state.assessmentReducer.fetchError;
export const getLatestAssessmentResultsFetchError = (state: RootState): string | null =>
    state.assessmentReducer.latestAssessmentResultsFetchError;
export const getFetchLatestAssessmentSuccess = (state: RootState): boolean =>
    state.assessmentReducer.fetchLatestAssessmentSuccess;
export const getFetchLatestAssessmentResultsSuccess = (state: RootState): boolean =>
    state.assessmentReducer.fetchLatestAssessmentResultsSuccess;
export const getFetchMyAssessmentsSuccess = (state: RootState): boolean =>
    state.assessmentReducer.fetchMyAssessmentsSuccess;
export const getCurrentAssessment = (state: RootState): Assessment | null =>
    state.assessmentReducer.currentAssessment;
export const getLatestAssessment = (state: RootState): LatestAssessment | null =>
    state.assessmentReducer.latestAssessment;
export const getLatestAssessmentResults = (
    state: RootState,
): LatestAssessmentResultsResponse | null => state.assessmentReducer.latestAssessmentResults;
export const getAssessmentAnswer = (state: RootState): AssessmentAnswerResponse | null =>
    state.assessmentReducer.assessmentAnswer;

export const getIsPosting = (state: RootState): boolean => state.assessmentReducer.isPosting;
export const getPostError = (state: RootState): string | null => state.assessmentReducer.postError;
export const getPostCreateAssessmentSuccess = (state: RootState): boolean =>
    state.assessmentReducer.postCreateAssessmentSuccess;
export const getPostAnswerSuccess = (state: RootState): boolean =>
    state.assessmentReducer.postAnswerSuccess;
export const getPostCompletedAssessmentSuccess = (state: RootState): boolean =>
    state.assessmentReducer.postCompleteAssessmentSuccess;

export const getLatestAssessmentLeaderResults = (state: RootState): AssessmentResult | undefined =>
    state.assessmentReducer.latestAssessmentResults?.leaderResults;
export const getLatestAssessmentPeerResults = (state: RootState): AssessmentResult[] | undefined =>
    state.assessmentReducer.latestAssessmentResults?.peerResults;
export const getLatestAssessmentPeerAverageResults = (
    state: RootState,
): AssessmentResult | undefined =>
    state.assessmentReducer.latestAssessmentResults?.peerAverageResults;

export const getPeerResultsByBehaviourId = (
    state: RootState,
    behaviourID: number,
): QuestionResult[] | undefined => {
    const peerResults = state.assessmentReducer.latestAssessmentResults?.peerResults || [];
    return peerResults.reduce((acc: QuestionResult[], curr: AssessmentResult) => {
        const filteredBehaviours = curr.topics.reduce(
            (acc: BehaviourResult[], curr: TopicResult) => [
                ...acc,
                ...curr.behaviours.filter(
                    (behaviour: BehaviourResult) => behaviour.id === behaviourID,
                ),
            ],
            [] as BehaviourResult[],
        );
        return [
            ...acc,
            ...filteredBehaviours.reduce(
                (acc: QuestionResult[], curr: BehaviourResult) => [...acc, ...curr.questions],
                [] as QuestionResult[],
            ),
        ];
    }, [] as QuestionResult[]);
};

export const getAssessmentResultsSummaryByTopicId = (
    state: RootState,
    topicId: number,
): AssessmentResultsSummary => {
    const leaderResults = state.assessmentReducer.latestAssessmentResults?.leaderResults;
    const leaderResultsTopics =
        state.assessmentReducer.latestAssessmentResults?.leaderResults?.topics || [];
    const leaderTopic = leaderResultsTopics.find((topic: TopicResult) => topic.id === topicId);
    const leaderBehaviours = leaderTopic?.behaviours;

    const peerAverageResults = state.assessmentReducer.latestAssessmentResults?.peerAverageResults;
    const peerAverageResultsTopics =
        state.assessmentReducer.latestAssessmentResults?.peerAverageResults?.topics || [];
    const peerAverageTopic = peerAverageResultsTopics.find(
        (topic: TopicResult) => topic.id === topicId,
    );
    const peerAverageBehaviours = peerAverageTopic?.behaviours;

    return {
        leaderBehaviours,
        peerAverageBehaviours,
        leaderScoreAverage: leaderResults?.average || 0,
        peerAverageScoreAverage: peerAverageResults?.average || 0,
        leaderPercentage: leaderResults?.percentage || 0,
        peerAveragePercentage: peerAverageResults?.percentage || 0,
    };
};

export const getCompareResults = (state: RootState): CompareAssessmentResult =>
    state.assessmentReducer.compareResults;
export const getIsFetchingCompareResults = (state: RootState): boolean =>
    state.assessmentReducer.isFetchingCompareResults;
export const getCompareResultsFetchError = (state: RootState): string | null =>
    state.assessmentReducer.fetchCompareResultsError;

import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Assessment } from 'src/types/shared/Assessment';

export const createNewAssessmentRequest = createAction('createNewAssessmentRequest');
export const createNewAssessmentSuccess = createAction<Assessment>('createNewAssessmentSuccess');
export const createNewAssessmentFailure = createAction('createNewAssessmentFailure ');

export const createNewAssessment =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(createNewAssessmentRequest());
        try {
            const { data }: AssessmentResponse = await api.post(`assessments`, {});
            dispatch(createNewAssessmentSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, createNewAssessmentFailure, e as APIError);
        }
    };

interface AssessmentResponse {
    data: Assessment;
}

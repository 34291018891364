import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetStateAction } from 'react';
import { fetchLatestAssessment } from '@actions/assessment';
import { useHistory } from 'react-router-dom';
import { getIsPosting, getPostAnswerSuccess, getPostError } from '@selectors/assessment';
import usePrevious from 'lib/src/hooks/usePrevious';
import ErrorModal from 'lib/src/components/modal/ErrorModal';
import {
    selectPeerAssessmentsIsPosting,
    selectPeerAssessmentsPostError,
    selectPeerPostAnswerSuccess,
} from '@selectors/peerAssessments';
import { fetchPeerAssessment } from '@actions/peerAssessment/fetchPeerAssessment';

const QuestionSelector: React.FC<QuestionSelectorProps> = ({
    question,
    setQuestion,
    questionsTotal,
    assessmentCode,
    type,
    handlePrevious,
    handleNext,
    showBreak,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showErrorModal, setShowErrorModal] = useState(false);

    // const nonApplicablePostBody = {
    //     assessmentCode: assessmentCode,
    //     questionID: questionID?.toString(),
    //     answer: null,
    // };

    const isSelfPosting = useSelector(getIsPosting);
    const isPeerPosting = useSelector(selectPeerAssessmentsIsPosting);
    const postSelfSuccess = useSelector(getPostAnswerSuccess);
    const postSelfError = useSelector(getPostError);
    const postPeerSuccess = useSelector(selectPeerPostAnswerSuccess);
    const postPeerError = useSelector(selectPeerAssessmentsPostError);

    const isPosting = type === 'self' ? isSelfPosting : isPeerPosting;
    const postSuccess = type === 'self' ? postSelfSuccess : postPeerSuccess;
    const postError = type === 'self' ? postSelfError : postPeerError;
    const prevProps = usePrevious({ postSuccess, postError });

    // function handlePostNAAnswer() {
    //     if (type === 'self') dispatch(postAssessmentAnswer(nonApplicablePostBody));
    //     else dispatch(postPeerAssessmentAnswer(nonApplicablePostBody));
    // }

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            if (question + 1 !== questionsTotal) {
                setQuestion((state: number) => state + 1);
            } else {
                if (type === 'peer') {
                    dispatch(fetchPeerAssessment(assessmentCode));
                } else {
                    dispatch(fetchLatestAssessment(assessmentCode));
                }

                history.push(
                    `${type === 'peer' ? '/peer' : ''}/complete-assessment/${assessmentCode}`,
                );
            }
        }
    }, [
        postSuccess,
        prevProps.postSuccess,
        assessmentCode,
        question,
        questionsTotal,
        setQuestion,
        history,
        type,
        dispatch,
    ]);

    useEffect(() => {
        if (postSelfError && !prevProps.postError) {
            setShowErrorModal(true);
        }
    }, [postSelfError, prevProps.postError]);

    return (
        <>
            <div className={`flex-row justify-between align-center question-selector-wrapper`}>
                <button
                    disabled={isPosting}
                    className={`question-selector flex-row justify-center align-center ${
                        !showBreak && question + 1 <= 1 && 'disabled'
                    }`}
                    onClick={handlePrevious}
                >
                    <i className="fas fa-chevron-left"></i>
                    Previous question
                </button>

                {/* Commenting out Not Applicable option as the client requested. I think might be better to keep it as they might want it back in the future. */}

                {/* {isPosting ? (
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <i className="loading far fa-spinner fa-spin"></i>
                    </div>
                ) : (
                    <button
                        className="underline"
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => {
                            handlePostNAAnswer();
                        }}
                    >
                        Not applicable
                    </button>
                )} */}

                {isPosting && (
                    <div style={{ flex: 1, textAlign: 'center' }}>
                        <i className="loading far fa-spinner fa-spin"></i>
                    </div>
                )}

                <button
                    disabled={isPosting}
                    className={`question-selector flex-row justify-center align-center`}
                    onClick={handleNext}
                >
                    {showBreak ? 'Continue the survey' : 'Next question'}
                    <i className="fas fa-chevron-right"></i>
                </button>
            </div>

            {showErrorModal && (
                <ErrorModal
                    description="There was an error with your request, please try again."
                    closeModal={() => setShowErrorModal(false)}
                />
            )}
        </>
    );
};

interface QuestionSelectorProps {
    question: number;
    setQuestion: Dispatch<SetStateAction<number>>;
    questionsTotal: number;
    assessmentCode: string;
    type: 'self' | 'peer';
    handlePrevious: () => void;
    handleNext: () => void;
    showBreak: boolean;
}

export default QuestionSelector;

import AssessmentInner from '@pages/assessment/AssessmentInner';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import useFetchPeerAssessmentData from './hooks/useFetchPeerAssessmentData';

interface PeerAssessmentProps {
    code: string;
}

const PeerAssessmentActive = ({ code }: PeerAssessmentProps) => {
    const {
        noData,
        isFetching,
        fetchError,
        peerAssessmentQuestions,
        peerAssessment,
        topicsBehaviors,
    } = useFetchPeerAssessmentData(code);

    return (
        <DataCheck isEmpty={noData} isLoading={isFetching} error={fetchError}>
            <AssessmentInner
                latestAssessment={peerAssessment}
                questionsArr={peerAssessmentQuestions}
                topicsBehaviors={topicsBehaviors}
                assessmentCode={code}
                type="peer"
            />
        </DataCheck>
    );
};

export default PeerAssessmentActive;

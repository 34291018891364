import { useMemo } from 'react';
import { CompareAssessmentResult } from 'src/types/shared/Assessment';
import { ChartStats, ChartTopic } from 'lib/src/components/charts/AssessmentChart';

const useTopicStatsForCompareChart = (
    topicsForAssessmentChart: ChartTopic[],
    compareResults: CompareAssessmentResult,
    averageScoreLabel: string,
) => {
    const topicStatsForCompareChart: ChartStats[] = useMemo(
        () =>
            [...topicsForAssessmentChart]
                .sort((a, b) => a.sort - b.sort)
                .map(topic => {
                    const percentage =
                        compareResults.topicResults.find(result => result.id === topic.id)
                            ?.percentage || 0;

                    return {
                        stats: [{ name: averageScoreLabel, percentage: +percentage.toFixed(0) }],
                    };
                }),
        [topicsForAssessmentChart, averageScoreLabel, compareResults.topicResults],
    );

    return topicStatsForCompareChart;
};

export default useTopicStatsForCompareChart;

import FlexWrapper from 'lib/src/components/layout/wrapper/FlexWrapper';
import Pod from '@components/pods/Pod';
import PageHeader from '@components/typography/PageHeader';
import Carousel from '@components/carousel/Carousel';
import useWindowDimensions from 'lib/src/hooks/useWindowDimensions';
import { mediaQueriesMobileFirst } from 'lib/src/constants/mediaQueries';
import LinkButton from 'lib/src/components/button/LinkButton';
import { useParams } from 'react-router-dom';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import { isEmpty } from 'lib/src/utils/generic';
import useFetchPeerAssessmentInfo from '@pages/peer/hooks/useFetchPeerAssessmentInfo';

const leadershipModelPillars = [
    {
        title: 'Be at our best',
        description:
            'Lorem ipsum dolor sit amet, consectetur sont a la adipiscing elit. Morbi eu nunc sagittis, vulputate justo sit amet, tincidunt tellus. Morbi eu nunc sagittis, vulputate justo sit amet.',
        icon: 'fa-star',
    },
    {
        title: 'Grow and inspire our teams',
        description:
            'Lorem ipsum dolor sit amet, consectetur sont a la adipiscing elit. Morbi eu nunc sagittis, vulputate justo sit amet, tincidunt tellus. Morbi eu nunc sagittis, vulputate justo sit amet.',
        icon: 'fa-users',
    },
    {
        title: 'Supporters and playing field',
        description:
            'Lorem ipsum dolor sit amet, consectetur sont a la adipiscing elit. Morbi eu nunc sagittis, vulputate justo sit amet, tincidunt tellus. Morbi eu nunc sagittis, vulputate justo sit amet.',
        icon: 'fa-handshake',
    },
    {
        title: 'Play to win',
        description:
            'Lorem ipsum dolor sit amet, consectetur sont a la adipiscing elit. Morbi eu nunc sagittis, vulputate justo sit amet, tincidunt tellus. Morbi eu nunc sagittis, vulputate justo sit amet.',
        icon: 'fa-trophy',
    },
];

const PeerAssessmentInfo = () => {
    const { code } = useParams<{ code: string }>();
    const windowSize = useWindowDimensions();
    const slidesPerView =
        windowSize?.width && windowSize?.width < mediaQueriesMobileFirst.lsTablet ? 1.5 : 4;

    const { peerAssessmentInfo, isFetching, fetchError } = useFetchPeerAssessmentInfo(code);

    return (
        <DataCheck isEmpty={isEmpty(peerAssessmentInfo)} isLoading={isFetching} error={fetchError}>
            <FlexWrapper
                id="assessment-info"
                direction="column"
                extraClasses="full-page"
                align="center"
            >
                <Pod>
                    <PageHeader centered={false}>
                        <strong>Welcome</strong>
                    </PageHeader>

                    <PageHeader tag="h2" omitDivider centered={false}>
                        <strong>
                            {`Leader 5.0 Peer Assessment - ${peerAssessmentInfo.firstName} ${peerAssessmentInfo.lastName}`}
                        </strong>
                    </PageHeader>

                    <p>
                        Lorem ipsum dolor sit amet, consectetur sont a la adipiscing elit. Morbi eu
                        nunc sagittis, vulputate justo sit amet, tincidunt tellus. Morbi eu nunc
                        sagittis, vulputate justo sit amet.
                    </p>
                </Pod>

                <div className="sub-heading-wrapper">
                    <h3>Leadership Model Pillars</h3>
                </div>

                <Carousel slidesPerView={slidesPerView}>
                    {leadershipModelPillars.map((pillar, i) => (
                        <Pod key={i}>
                            <div className="icon-wrapper">
                                <i className={`fal ${pillar.icon}`} />
                            </div>
                            <h4>{pillar.title}</h4>
                            <p>{pillar.description}</p>
                        </Pod>
                    ))}
                </Carousel>

                <div className="button-wrapper justify-center">
                    <LinkButton href={`/peer/assessment/${code || ''}`} source="positive">
                        I'm Ready
                    </LinkButton>
                </div>
            </FlexWrapper>
        </DataCheck>
    );
};

export default PeerAssessmentInfo;

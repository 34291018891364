import { createAction } from '@reduxjs/toolkit';
import { PeerAssessmentInfo } from '@reducers/peerAssessments';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { APIError } from 'lib/src/types/APIError';

export const fetchPeerAssessmentInfoRequest = createAction('fetchPeerAssessmentInfoRequest');
export const fetchPeerAssessmentInfoSuccess = createAction<PeerAssessmentInfo>(
    'fetchPeerAssessmentInfoSuccess',
);
export const fetchPeerAssessmentInfoFailure = createAction('fetchPeerAssessmentInfoFailure');

export const fetchPeerAssessmentInfo = (assessmentCode: string) => async (dispatch: any) => {
    dispatch(fetchPeerAssessmentInfoRequest());

    try {
        const { data } = await api.get<PeerAssessmentInfo>(
            `peer/assessment/${assessmentCode}/info`,
        );

        dispatch(fetchPeerAssessmentInfoSuccess(data));
    } catch (error) {
        handleApiErrors(dispatch, fetchPeerAssessmentInfoFailure, error as APIError);
    }
};

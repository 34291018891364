import {
    fetchCompareResultsByType,
    fetchCompareResultsByTypeAbove,
    fetchCompareResultsByEntity,
} from '@actions/assessment';
import useFetchTopicsBehaviors from '@pages/assessment/hooks/useFetchTopicsBehaviors';
import useFetchLatestAssessmentResults from '@pages/latestAssessment/hooks/useFetchLatestAssessmentResults';
import {
    getCompareResults,
    getCompareResultsFetchError,
    getIsFetchingCompareResults,
} from '@selectors/assessment';
import { DropdownOption } from 'lib/src/types/shared/DropdownOption';
import { onChangeFunction } from 'lib/src/types/shared/FormInputProps';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CompareAssessmentResult,
    CompareTopicResult,
    LatestAssessmentResultsResponse,
} from 'src/types/shared/Assessment';
import { Topic, TopicResult, TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';
import { compareLevel, compareLevelLabels, compareType } from '../constants';
import { getAllTopicsBehaviors } from '@selectors/topicsBehaviors';

const useCompareResults = (): ReturnType => {
    const dispatch = useDispatch();
    const [level, setLevel] = useState<compareLevel>(compareLevel.MY_LEVEL);
    const [type, setType] = useState<compareType>(compareType.SELF);

    const {
        latestAssessmentResults,
        isFetching: isFetchingLatestAssessmentResults,
        fetchError: latestAssessmentResultsFetchError,
    } = useFetchLatestAssessmentResults();

    const {
        topicsBehaviors: { topics = [] },
        isFetching: isFetchingTopicsBehaviours,
        fetchError: topicsBehavioursFetchError,
    } = useFetchTopicsBehaviors();

    const compareResults = useSelector(getCompareResults);
    const isFetchingCompareResults = useSelector(getIsFetchingCompareResults);
    const compareResultsFetchError = useSelector(getCompareResultsFetchError);
    const topicsBehaviors = useSelector(getAllTopicsBehaviors);

    const canGetMyTypeComparison = latestAssessmentResults?.canGetMyTypeComparison || false;
    const canGetOneLevelUpComparison = latestAssessmentResults?.canGetOneLevelUpComparison || false;

    const topicResultsWithInfo = useMemo<CompareTopicResultWithInfo[]>(() => {
        try {
            return compareResults.topicResults.map((result: CompareTopicResult) => {
                const thisTopicInfo = topics.find((t: Topic) => t.id === result.id);
                const { name, summary, colour, colourDark } = thisTopicInfo;
                const thisLatestTopic = latestAssessmentResults?.leaderResults.topics.find(
                    (topic: TopicResult) => topic.id === result.id,
                );
                return {
                    ...result,
                    myPercentage: thisLatestTopic.percentage,
                    myAverage: thisLatestTopic.average,
                    name,
                    summary,
                    colour,
                    colourDark,
                };
            });
        } catch (e) {
            // console.warn(e);
            return [];
        }
    }, [latestAssessmentResults?.leaderResults?.topics, compareResults, topics]);

    const compareLevelDropdownOptions = useMemo<DropdownOption<string>[]>(() => {
        return Object.entries(compareLevelLabels).reduce(
            (acc: DropdownOption<string>[], curr: string[]) => {
                const [value, label] = curr;
                if (
                    (value === compareLevel.MY_LEVEL && !canGetMyTypeComparison) ||
                    (value === compareLevel.ONE_LEVEL_UP && !canGetOneLevelUpComparison)
                )
                    return acc;
                return [...acc, { label, value }];
            },
            [] as DropdownOption<string>[],
        );
    }, [canGetMyTypeComparison, canGetOneLevelUpComparison]);

    const handleChange = (name: string, value: string | number | null) => {
        if (name === 'compareLevel') setLevel(value as compareLevel);
        if (name === 'compareType') setType(value as compareType);
    };

    useEffect(() => {
        if (level === compareLevel.ONE_LEVEL_UP) dispatch(fetchCompareResultsByTypeAbove());
        else if (level === compareLevel.ENTITY) dispatch(fetchCompareResultsByEntity());
        else dispatch(fetchCompareResultsByType());
    }, [dispatch, level]);

    const isFetching =
        isFetchingCompareResults || isFetchingTopicsBehaviours || isFetchingLatestAssessmentResults;
    const fetchError =
        compareResultsFetchError || topicsBehavioursFetchError || latestAssessmentResultsFetchError;

    return {
        level,
        type,
        topicsBehaviors,
        latestAssessmentResults,
        compareResults,
        topicResultsWithInfo,
        isFetching,
        fetchError,
        handleChange,
        canGetMyTypeComparison,
        canGetOneLevelUpComparison,
        compareLevelDropdownOptions,
    };
};

export interface CompareTopicResultWithInfo extends CompareTopicResult {
    name: string;
    summary: string;
    colour: string;
    colourDark: string;
    myPercentage: number | null;
    myAverage: number | null;
}

interface ReturnType {
    level: compareLevel;
    type: compareType;
    topicsBehaviors: TopicsBehaviour;
    latestAssessmentResults: LatestAssessmentResultsResponse | null;
    compareResults: CompareAssessmentResult;
    topicResultsWithInfo: CompareTopicResultWithInfo[];
    isFetching: boolean;
    fetchError: string | null;
    handleChange: onChangeFunction<string | number | null>;
    canGetMyTypeComparison: boolean;
    canGetOneLevelUpComparison: boolean;
    compareLevelDropdownOptions: DropdownOption<string>[];
}

export default useCompareResults;

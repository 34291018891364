import { DropdownOption } from 'lib/src/types/shared/DropdownOption';

export enum assessmentChartKey {
    SELF = 'Self assessment',
    PEER = 'Peer assessment',
    TARGET = 'Target assessment',
}

export enum compareLevel {
    MY_LEVEL = 'myLevel',
    ONE_LEVEL_UP = 'oneLevelUp',
    ENTITY = 'entity',
}

export const compareLevelLabels = {
    [compareLevel.MY_LEVEL]: 'Leaders at the same level as me',
    [compareLevel.ONE_LEVEL_UP]: 'Leaders one hierarchical level above me',
    [compareLevel.ENTITY]: 'Leaders with my position',
};

// export const compareLevelDropDownOptions: DropdownOption<string>[] = Object.entries(
//     compareLevelLabels,
// ).reduce((acc: DropdownOption<string>[], curr: string[]) => {
//     const [value, label] = curr;
//     return [...acc, { label, value }];
// }, [] as DropdownOption<string>[]);

export enum compareType {
    SELF = 'self',
    PEER = 'peer',
}

export const compareTypeLabels = {
    [compareType.SELF]: 'Self Assessment',
    [compareType.PEER]: 'Peer Assessment',
};

export const compareTypeDropDownOptions: DropdownOption<string>[] = Object.entries(
    compareTypeLabels,
).reduce((acc: DropdownOption<string>[], curr: string[]) => {
    const [value, label] = curr;
    return [...acc, { label, value }];
}, [] as DropdownOption<string>[]);

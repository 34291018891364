import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchLatestAssessmentResults } from '@actions/assessment';
import {
    getLatestAssessmentResults,
    getLatestAssessmentResultsFetchError,
    getLatestAssessmentResultsIsFetching,
} from '@selectors/assessment';

const useFetchLatestAssessmentResults = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getLatestAssessmentResultsIsFetching);
    const fetchError = useSelector(getLatestAssessmentResultsFetchError);
    const latestAssessmentResults = useSelector(getLatestAssessmentResults);

    useEffect(() => {
        dispatch(fetchLatestAssessmentResults());
    }, [dispatch]);

    return { isFetching, fetchError, latestAssessmentResults };
};

export default useFetchLatestAssessmentResults;

import { RootState } from '@reducers/index';

import { Behaviour, Topic, TopicsBehaviour } from 'src/types/shared/TopicsBehaviors';

export const getAllTopicsBehaviors = (state: RootState): TopicsBehaviour =>
    state.topicsBehaviorsReducer.topicsBehaviors;
export const geTopicsBehaviorsIsFetching = (state: RootState): boolean =>
    state.topicsBehaviorsReducer.isFetching;
export const geTopicsBehaviorsFetchError = (state: RootState): string | null =>
    state.topicsBehaviorsReducer.fetchError;
export const getAllTopics = (state: RootState): Topic[] =>
    state.topicsBehaviorsReducer.topicsBehaviors.topics;
export const getAllBehaviors = (state: RootState): Behaviour[] =>
    state.topicsBehaviorsReducer.topicsBehaviors.behaviours;
export const getTopicById = (state: RootState, id: number): Topic | undefined =>
    state.topicsBehaviorsReducer.topicsBehaviors.topics.find(t => t.id === id);
export const getBehaviorById = (state: RootState, id: number): Behaviour | undefined =>
    state.topicsBehaviorsReducer.topicsBehaviors.behaviours.find(b => b.id === id);

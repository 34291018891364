import useAssessmentDataFetch from './hooks/useAssessmentDataFetch';

import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import AssessmentInner from './AssessmentInner';
import Meta from 'lib/src/components/meta/Meta';

const Assessment = () => {
    const {
        noData,
        isLoading,
        error,
        currentAssessment,
        latestAssessment,
        questionsShuffledArr,
        topicsBehaviors,
    } = useAssessmentDataFetch();

    return (
        <>
            <Meta title="Assessment" />
            <DataCheck isEmpty={noData} isLoading={isLoading} error={error}>
                {currentAssessment ? (
                    <AssessmentInner
                        latestAssessment={latestAssessment}
                        questionsArr={questionsShuffledArr}
                        topicsBehaviors={topicsBehaviors}
                        assessmentCode={currentAssessment.assessmentCode}
                        type="self"
                    />
                ) : (
                    latestAssessment && (
                        <AssessmentInner
                            latestAssessment={latestAssessment}
                            questionsArr={questionsShuffledArr}
                            topicsBehaviors={topicsBehaviors}
                            assessmentCode={latestAssessment.assessmentCode}
                            type="self"
                        />
                    )
                )}
            </DataCheck>
        </>
    );
};

export default Assessment;

import RangeSlider from '@components/form/RangeSlider';
import Pod from '@components/pods/Pod';
import Title from 'lib/src/components/typography/Title';
import React, { Dispatch, SetStateAction } from 'react';
import { AssessmentQuestion } from 'src/types/shared/Assessment';
import QuestionSelector from './QuestionSelector';
import useAssessmentPod from './hooks/useAssessmentPod';

const AssessmentPod: React.FC<AssessmentPodProps> = ({
    question,
    questionsArr,
    form,
    handleChange,
    pointsArray,
    setQuestion,
    assessmentCode,
    type,
    showBreak,
    breakNumber,
    setShowBreak,
}) => {
    const { breakImage, handlePrevious, handleNext } = useAssessmentPod({
        showBreak,
        breakNumber,
        setShowBreak,
        question,
        setQuestion,
        type,
        assessmentCode,
        questionID: questionsArr[question]?.id,
        answer: form.rangeValues,
    });

    return (
        <Pod extraClasses={`range-slider-pod ${showBreak ? 'break' : ''}`}>
            {showBreak ? (
                <img className="break-image" alt="" src={breakImage} />
            ) : (
                <>
                    <Title className="question-text">
                        {questionsArr && questionsArr[question]?.text}
                    </Title>

                    <div className="range-slider flex-row">
                        <RangeSlider
                            value={form.rangeValues}
                            name="rangeValues"
                            onChange={handleChange}
                            showHeatMapTrack
                            pointsArray={pointsArray}
                            min={1}
                            max={pointsArray.length}
                            step={1}
                            hideThumb={false}
                            direction="up"
                        />
                    </div>
                    <br />
                    <br />
                    <div className="thin-divider" />
                    <br />
                    <br />
                </>
            )}

            <QuestionSelector
                question={question}
                setQuestion={setQuestion}
                questionsTotal={questionsArr.length}
                assessmentCode={assessmentCode}
                type={type}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                showBreak={showBreak}
            />
        </Pod>
    );
};

interface AssessmentPodProps {
    question: number;
    questionsArr: AssessmentQuestion[];
    form: { rangeValues: number[] };
    handleChange: (name: 'rangeValues', value: number[]) => void;
    pointsArray: { text: string }[];
    setQuestion: Dispatch<SetStateAction<number>>;
    assessmentCode: string;
    type: 'self' | 'peer';
    showBreak: boolean;
    breakNumber: number;
    setShowBreak: React.Dispatch<React.SetStateAction<boolean>>;
}

export default AssessmentPod;

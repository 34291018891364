import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getPostAnswerSuccess } from '@selectors/assessment';
import { selectPeerPostAnswerSuccess } from '@selectors/peerAssessments';
import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import useQueryParams from 'lib/src/hooks/useQueryParams';
import { AssessmentQuestion, LatestAssessment } from 'src/types/shared/Assessment';

interface HookProps {
    questionsArr: AssessmentQuestion[];
    type: 'self' | 'peer';
    latestAssessment?: LatestAssessment | null;
    assessmentCode: string;
}

const pointsArray = [
    { text: 'Strongly agree' },
    { text: '' },
    { text: 'Agree' },
    { text: '' },
    { text: 'Somewhat agree' },
    { text: '' },
    { text: 'Disagree' },
    { text: '' },
    { text: 'Strongly disagree' },
];

const startPoint = 4;

const useAssessmentInner = ({ questionsArr, type, latestAssessment }: HookProps) => {
    const params = useQueryParams();
    const questionNumberString = params.get('question');

    const [hasUpdatedInitialAnswers, setHasUpdatedInitialAnswers] = useState(false);
    const [answeredQuestionsIDs, setAnsweredQuestionIDs] = useState<number[]>([]);

    useEffect(() => {
        if (hasUpdatedInitialAnswers) return;

        const answeredIDs: number[] = [];

        latestAssessment?.questions.forEach(q => {
            if (q.isAnswered) {
                answeredIDs.push(q.id);
            }
        });

        setAnsweredQuestionIDs(answeredIDs);
        setHasUpdatedInitialAnswers(true);
    }, [latestAssessment?.questions, hasUpdatedInitialAnswers]);

    const questionToBeginIndex = useMemo(() => {
        const questions = latestAssessment?.questions ?? [];
        if (!questions.length) return 0;

        const finalQuestionIndex = questionsArr.length - 1;
        let nextQuestionIndex = questionsArr.findIndex(({ id }) =>
            questions.find(q => q.id === id && !q.isAnswered),
        );
        if (nextQuestionIndex === -1) nextQuestionIndex = finalQuestionIndex;
        if (nextQuestionIndex < 0) return 0;
        return nextQuestionIndex;
    }, [questionsArr, latestAssessment]);

    const [question, setQuestion] = useState<number>(questionToBeginIndex);
    const answeredQuestion = latestAssessment?.questions.find(
        item => item?.id === questionsArr[question]?.id,
    )?.answer;

    const [form, handleChange] = useForm({
        rangeValues: [answeredQuestion || startPoint],
    });

    useEffect(() => {
        if (!!questionNumberString) {
            const questionNumber = parseInt(questionNumberString);
            setQuestion(questionNumber - 1);
        }
    }, [questionNumberString]);

    const completedScore =
        questionsArr.length &&
        answeredQuestionsIDs &&
        Math.floor((answeredQuestionsIDs.length / questionsArr.length) * 100);

    const postSelfAnswerSuccess = useSelector(getPostAnswerSuccess);
    const prevPostSelfAnswerSuccess = usePrevious(postSelfAnswerSuccess);
    const postPeerAnswerSuccess = useSelector(selectPeerPostAnswerSuccess);
    const prevPostPeerAnswerSuccess = usePrevious(postPeerAnswerSuccess);

    const postAnswerSuccess = type === 'self' ? postSelfAnswerSuccess : postPeerAnswerSuccess;
    const prevPostAnswerSuccess =
        type === 'self' ? prevPostSelfAnswerSuccess : prevPostPeerAnswerSuccess;

    useEffect(() => {
        answeredQuestion
            ? handleChange('rangeValues', [answeredQuestion])
            : handleChange('rangeValues', [startPoint]);
    }, [answeredQuestion, handleChange]);

    useEffect(() => {
        if (postAnswerSuccess && !prevPostAnswerSuccess) {
            if (!answeredQuestionsIDs.includes(questionsArr[question].id)) {
                setAnsweredQuestionIDs(state => [...state, questionsArr[question].id]);
            }

            answeredQuestion
                ? handleChange('rangeValues', [answeredQuestion])
                : handleChange('rangeValues', [startPoint]);
        }
    }, [
        postAnswerSuccess,
        prevPostAnswerSuccess,
        handleChange,
        answeredQuestion,
        question,
        questionsArr,
        answeredQuestionsIDs,
    ]);

    return { question, setQuestion, completedScore, pointsArray, form, handleChange };
};

export default useAssessmentInner;

import { Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import BackgroundImage1 from '../../../_content/question-break/question-break1.jpg';
import BackgroundImage2 from '../../../_content/question-break/question-break2.jpg';
import BackgroundImage3 from '../../../_content/question-break/question-break3.jpg';
import BackgroundImage4 from '../../../_content/question-break/question-break4.jpg';
import BackgroundImage5 from '../../../_content/question-break/question-break5.jpg';

import { postAssessmentAnswer } from '@actions/assessment';
import { postPeerAssessmentAnswer } from '@actions/peerAssessment/postPeerAssessmentAnswer';

const useAssessmentPod = (props: Props) => {
    const {
        breakNumber,
        showBreak,
        setShowBreak,
        question,
        setQuestion,
        type,
        assessmentCode,
        questionID,
        answer,
    } = props;

    const dispatch = useDispatch();

    const breakBackgrounds = [
        BackgroundImage1,
        BackgroundImage2,
        BackgroundImage3,
        BackgroundImage4,
        BackgroundImage5,
    ];

    const breakImage = breakBackgrounds[breakNumber - 1];

    const handlePostAnswer = () => {
        const postBody = {
            assessmentCode: assessmentCode,
            questionID: questionID?.toString(),
            answer: answer[0]?.toString(),
        };

        if (type === 'self') dispatch(postAssessmentAnswer(postBody));
        else dispatch(postPeerAssessmentAnswer(postBody));
    };

    const handlePrevious = () => {
        question + 1 > 1 && setQuestion(state => state - 1);
        if (showBreak) setShowBreak(false);
    };

    const handleNext = () => {
        if (showBreak) {
            setShowBreak(false);
        } else {
            handlePostAnswer();
        }
    };

    return { breakImage, handlePrevious, handleNext };
};

interface Props {
    showBreak: boolean;
    breakNumber: number;
    setShowBreak: React.Dispatch<React.SetStateAction<boolean>>;
    question: number;
    setQuestion: Dispatch<SetStateAction<number>>;
    type: 'self' | 'peer';
    assessmentCode: string;
    questionID: number;
    answer: number[];
}

export default useAssessmentPod;

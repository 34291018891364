import { createAction } from '@reduxjs/toolkit';
import { APIError } from 'lib/src/types/APIError';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { LatestAssessment } from 'src/types/shared/Assessment';

export const fetchCompareResultsByTypeAboveRequest = createAction(
    'fetchCompareResultsByTypeAboveRequest',
);
export const fetchCompareResultsByTypeAboveSuccess = createAction<LatestAssessment>(
    'fetchCompareResultsByTypeAboveSuccess',
);
export const fetchCompareResultsByTypeAboveFailure = createAction(
    'fetchCompareResultsByTypeAboveFailure',
);

export const fetchCompareResultsByTypeAbove =
    () =>
    async (dispatch: AppDispatch): Promise<void> => {
        dispatch(fetchCompareResultsByTypeAboveRequest());
        try {
            const { data } = await api.get<LatestAssessment>(`assessments/compare/typeabove`);
            dispatch(fetchCompareResultsByTypeAboveSuccess(data));
        } catch (e) {
            handleApiErrors(dispatch, fetchCompareResultsByTypeAboveFailure, e as APIError);
        }
    };

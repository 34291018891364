import PeerResultsInner from './PeerResultsInner';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import useFetchTopicsBehaviors from '@pages/assessment/hooks/useFetchTopicsBehaviors';
import useFetchPeerAssessmentResults from './hooks/useFetchPeerAssessmentResults';
import { isEmpty } from 'lib/src/utils/generic';
import { LatestAssessmentResultsResponse } from 'src/types/shared/Assessment';
import Meta from 'lib/src/components/meta/Meta';
import { useParams } from 'react-router-dom';

const PeerResults = () => {
    const { code } = useParams<{ code: string }>();
    const {
        topicsBehaviors,
        isFetching: isFetchingTopicsBehaviours,
        fetchError: topicsBehavioursFetchError,
    } = useFetchTopicsBehaviors();

    const {
        peerAssessmentResults,
        isFetching: isFetchingPeerResults,
        fetchError: peerResultsFetchError,
    } = useFetchPeerAssessmentResults(code);

    return (
        <>
            <Meta title="Submitted Assessment Results" />
            <DataCheck
                isEmpty={isEmpty(topicsBehaviors) || isEmpty(peerAssessmentResults)}
                isLoading={isFetchingTopicsBehaviours || isFetchingPeerResults}
                error={topicsBehavioursFetchError || peerResultsFetchError}
            >
                <PeerResultsInner
                    topicsBehaviours={topicsBehaviors}
                    peerAssessmentResults={peerAssessmentResults as LatestAssessmentResultsResponse}
                />
            </DataCheck>
        </>
    );
};

export default PeerResults;
